import { FileText } from "react-feather";
import styled from "styled-components";

export const ImageWrapper = styled.div`
  height: 37.5rem;
  width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.main};

  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  span {
    color: ${props => props.theme.colors.text};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  background: ${props => props.theme.colors.contentBackground};
  padding: .6rem 1rem;
`;

export const Files = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
  padding: .6rem 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  overflow-x: scroll;

  img {
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const DocumentIcon = styled(FileText)`
  cursor: pointer;
`;

export const MessageForm = styled.div``;
