import { Container } from "./styles";
import { StatusAlertProps } from "./types";

export function StatusAlert({ children }: StatusAlertProps) {
  return (
    <Container>
      {children}
    </Container>
  );
}
