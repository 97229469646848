import { LoadingSpinner } from "components/LoadingSpinner";
import { Message } from "components/Message";
import { isAfter, parseISO, startOfDay } from 'date-fns';
import { useContacts } from "hooks/useContacts";
import { useMessages } from "hooks/useMessages";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { ChatMessage } from "types/ChatMessage";
import { strToDate } from "utils/Datetime";
import { isEmpty } from "utils/Misc";
import { Container, Loading, MessagesDatetime } from "./styles";

type MessagesProps = {
  searchedMsg?: string;
}

export function Messages({ searchedMsg }: MessagesProps) {
  const {
    messagesQuery: { query, loadMoreRef },
    previewMessages,
  } = useMessages();
  const { selectedContact } = useContacts();

  const { data, isFetchingNextPage } = query;

  const [messages, setMessages] = useState<ChatMessage[]>([]);

  useEffect(() => {
    if (!data) return;

    const allPagesMessages: ChatMessage[] = [];

    data.pages.map((page) => {
      return page.data.map((message) => {
        return allPagesMessages.push(message);
      });
    });

    setMessages([...allPagesMessages].reverse());
  }, [data]);

  const didScrollToMessage = useRef(false);

  useEffect(() => {
    if (!data?.pages.length || !searchedMsg) return;

    const targetMsg = document.getElementById(searchedMsg);

    if (targetMsg && !didScrollToMessage.current) {
      targetMsg.scrollIntoView({ behavior: 'smooth' });
      didScrollToMessage.current = true;
    }
  }, [data?.pages.length, searchedMsg]);

  const shouldShowCurrentDay = useCallback((message: ChatMessage): boolean => {
    if (isEmpty(messages)) return false;

    const index = messages.indexOf(message);
    const previousMessage = messages[index - 1 > 0 ? index - 1 : 0];

    const previousMessageDate = startOfDay(parseISO(previousMessage.time));
    const currentMessageDate = startOfDay(parseISO(message.time));

    if (previousMessage === message || isAfter(currentMessageDate, previousMessageDate)) {
      return true;
    }

    return false;
  }, [messages]);

  return (
    <Container>
      {previewMessages && previewMessages
        .filter(message => {
          return message.contact_id === selectedContact.id
        })
        .map(message => (
          <Message key={message.id} message={message} isSending />
        ))
        .reverse()
      }

      {data && data.pages && data.pages.map((page, index) => (
        <Fragment key={index}>
          {page.data.filter((message) => !!message.type).map((message) => (
            <Fragment key={message.id}>
              <div>
                <Message message={message} />
              </div>
              {shouldShowCurrentDay(message) && <MessagesDatetime>{strToDate(message.time)}</MessagesDatetime>}
            </Fragment>
          ))}
        </Fragment>
      ))}
      {isFetchingNextPage && (
        <Loading>
          <LoadingSpinner size={25} thickness={2} />
        </Loading>
      )}
      <div ref={loadMoreRef} style={{ visibility: 'hidden' }}></div>
    </Container>
  );
}
