import { useAuth } from "hooks/useAuth";
import { useCount } from "hooks/utils/useCount";
import { createContext, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { api } from "services/api";
import { Contact, ContactsContextData, ContactsProviderProps } from "types/Contact";
import { isEmpty } from "utils/Misc";
import { handleRequestError } from "utils/Request";

export const ContactsContext = createContext({} as ContactsContextData);

export function ContactsProvider({ children }: ContactsProviderProps) {
  const [currentEndpoint, setCurrentEndpoint] = useState<string | null>(null);
  const [selectedContact, setSelectedContact] = useState<Contact>({} as Contact);
  const [isSelectingContact, setIsSelectingContact] = useState(false);

  const { search, pathname } = useLocation();
  const history = useHistory();

  const { user } = useAuth();

  const { revalidateCount } = useCount();

  const markConversationAsRead = useCallback(async (contact: Contact) => {
    try {
      await api.post(`/messages/${contact.id}/mark-all-read`);
      revalidateCount();
    } catch (error) {
      handleRequestError('Ocorreu um erro ao marcar conversa como lida.');
    }
  }, [revalidateCount]);

  async function syncMessages(contactToSync: Contact | string, amount = 20) {
    // await api.post(`/messages/sync/${contactToSync}?count=${amount}`);
  }

  const selectContact = async (contact: Contact | string) => {
    setIsSelectingContact(true);
    const queryParams = new URLSearchParams(search);

    let contactId = contact;

    if (typeof contact !== 'string') {
      await setSelectedContact(contact);

      if (queryParams.has('contato') && isEmpty(contact)) {
        queryParams.delete('contato')
        history.replace({
          search: queryParams.toString(),
        });
      } else {
        history.push({
          pathname,
          search: `contato=${contact.id}${contact.message ? `&msg=${contact.message?.id ?? ''}` : ''}`,
        });
      }

      contactId = contact.id;
    }

    let updatedContact: Contact = {} as Contact;

    if (contactId) {
      try {
        const response = await api.get(`/contacts/${contactId}`);
        if (contact !== response.data && !isEmpty(response.data)) {
          setSelectedContact(response.data);
          updatedContact = response.data;
        }
      } catch (error) {
        handleRequestError(error, 'Erro ao atualizar informações do contato selecionado. As informações atuais do contato podem estar desatualizadas.');
      }

      try {
        await syncMessages(contactId);
      } catch (error) {
        handleRequestError(error, 'Erro ao sincronizar mensagens. Por favor, tente realizar a sincronização manualmente!');
      }
    }

    if (
      !isEmpty(updatedContact)
      && updatedContact.user
      && user
      && updatedContact.user.id === user.id
      && updatedContact.new_messages_count > 0
    ) {
      await markConversationAsRead(updatedContact);
    }

    setIsSelectingContact(false);
  }

  async function fetchCurrentContact() {
    const query = new URLSearchParams(search);
    const queryContact = query.get('contato');

    if (!queryContact) {
      if (!isEmpty(selectedContact)) {
        selectContact({} as Contact);
      }
      return;
    }

    if (queryContact === selectedContact.id) {
      return;
    }

    try {
      return selectContact(queryContact);
    } catch (error) {
      handleRequestError(error, 'Um erro ocorreu ao buscar o contato selecionado. Por favor, tente novamente!');
    }
  }

  useEffect(() => {
    fetchCurrentContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContactsContext.Provider value={{
      isSelectingContact,
      currentEndpoint,
      setCurrentEndpoint,
      selectedContact,
      selectContact,
    }}>
      {children}
    </ContactsContext.Provider>
  )
}
