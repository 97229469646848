import { useController } from "react-hook-form";
import { InputWrapper } from "styles/InputWrapper";
import { ContentEditableProps } from "./types";
import { FormControl } from "styles/FormControl";
import { KeyboardEvent } from "react";
import { ContentEditableEvent } from "react-contenteditable";
import { usePaste } from "hooks/usePaste";
import { escapeHtml } from "utils/Misc";

export function ContentEditable({
  name,
  control,
  label,
  id,
  placeholder,
  icons,
  onSubmit,
  ...props
}: ContentEditableProps) {
  const { field, fieldState } = useController({ name, control });

  const { getFiles, getAsPlainText } = usePaste();

  function handleKeyDown(e: KeyboardEvent<HTMLDivElement>) {
    if (
      (
        e.key.toLowerCase() === 'b'
        || e.key.toLowerCase() === 'i'
        || e.key.toLowerCase() === 'u'
      )
      && (e.ctrlKey || e.metaKey)
    ) {
      e.preventDefault();
      return false;
    }

    if (
      e.key !== 'Enter'
      || (e.key === 'Enter' && e.shiftKey)
    ) return;

    onSubmit();
    e.preventDefault();
  }

  function handleChange(e: ContentEditableEvent) {
    const content = escapeHtml(e.currentTarget.innerText);

    if (!content.trim()) {
      return field.onChange(content.trim());
    }

    return field.onChange(content);
  }

  async function handlePaste(event: unknown) {
    const e = event as ClipboardEvent;
    e.preventDefault();

    if (getFiles(e).length > 0) return false;

    getAsPlainText(e);
  }

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}

      <InputWrapper>
        {icons}
        <FormControl
          html={field.value ?? ''}
          data-placeholder={placeholder}
          id={id}
          className="editableContent"
          hasError={!!fieldState.error}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onPaste={handlePaste}
          {...props}
        />
      </InputWrapper>

      {fieldState.error && <span className="input-error-message">{fieldState.error}</span>}
    </>
  );
}
