import styled from "styled-components";

export const MacroListWrapper = styled.div`
  max-height: 37.5rem;
  margin-bottom: 1rem;
`;

export const ActionsButtons = styled.div`
  display: flex;
  gap: .5rem;
  justify-content: flex-end;
`;
