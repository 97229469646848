import * as Yup from 'yup';

export function sendMessageValidator() {
  return Yup.object().shape({
    message: Yup
      .string(),
    annotation: Yup
      .bool(),
  });
}
