import { AxiosError } from "axios";
import { NewConversationData } from "components/NewConversationModal/types";
import { useContacts } from "hooks/useContacts";
import { useCount } from "hooks/utils/useCount";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";
import { api } from "services/api";
import { Contact } from "types/Contact";
import { handleFormErrors } from "utils/Form";
import { axiosError, axiosSuccess, handleRequestSuccess, handleRequestError } from "utils/Request";

type StartNewConversationProps = {
  data: NewConversationData;
}

async function startConversation(newData: NewConversationData): Promise<Contact> {
  const { data } = await api.post('/contacts', { ...newData }).then(axiosSuccess).catch(axiosError);
  return data;
}

export function useStartNewConversation(errorSetter?: UseFormSetError<FieldValues>) {
  const { selectContact } = useContacts();
  const { revalidateCount } = useCount();

  return useMutation(({ data }: StartNewConversationProps) => startConversation(data), {
    onSuccess: (data: Contact) => {
      handleRequestSuccess(`Conversa com o contato "${data.name}" iniciada com sucesso!`);
      selectContact(data);
      revalidateCount();
    },
    onError: (error: AxiosError) => {
      if (errorSetter) {
        handleFormErrors(error, errorSetter);
        return;
      }

      handleRequestError(error, 'Erro ao iniciar nova conversa.');
    }
  });
}
