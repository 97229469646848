import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";

export const isAxiosError = (error: unknown): error is AxiosError =>
  (error as AxiosError).response !== undefined;

export const axiosSuccess = (response: AxiosResponse) =>  response;
export const axiosError = (error: AxiosError) => {
  throw error;
}

export function handleRequestSuccess(
  message = 'Operação executada com sucesso!',
) {
  toast.success(message);
}

export function handleRequestError(
  error: unknown,
  defaultMessage = 'Ocorreu um erro inesperado. Por favor, tente novamente!',
) {
  let message = defaultMessage;

  if (isAxiosError(error) && error.response && error.response.data.message) {
    message = error.response.data.message;
  }

  toast.error(message);
}
