import { RefObject, useEffect, useState } from "react";
import { useClickOutside } from "./useClickOutside";

export function useComponentVisible(
  componentRef: RefObject<HTMLDivElement>,
  action: (() => any) | null = null,
  triggerRef: RefObject<HTMLDivElement> | null = null
) {
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const { handleClickOutside, handleHideOnEscape } = useClickOutside(
    componentRef,
    removeVisibility,
    triggerRef,
  );

  function removeVisibility() {
    setIsComponentVisible(false);
    if (!action) return;
    action();
  }

  function handleClick(e: Event) {
    handleClickOutside(e);
  }

  function handleKeyDown(e: KeyboardEvent) {
    handleHideOnEscape(e);
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, true);
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
      document.removeEventListener('click', handleClick, true);
    };
  });

  return { isComponentVisible, setIsComponentVisible };
}
