import { TransferContactModal } from "components/TransferContactModal";
import { useContacts } from "hooks/useContacts";
import { useState } from "react";
import { Button } from "styles/Button";

export function TransferButton() {
  const [isOpen, setIsOpen] = useState(false);

  const { selectedContact } = useContacts();

  function handleOpenTransferModal() {
    setIsOpen(true);
  }

  function handleCloseTransferModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Button
        medium={!window.matchMedia("(max-width: 1366px)").matches}
        small={window.matchMedia("(max-width: 1366px)").matches}
        onClick={handleOpenTransferModal}
      >
        Transferir
      </Button>

      <TransferContactModal
        contact={{
          id: selectedContact.id,
          name: selectedContact.name,
          categoryId: selectedContact.category?.id,
        }}
        isOpen={isOpen}
        onRequestClose={handleCloseTransferModal}
      />
    </>
  );
}
