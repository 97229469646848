import { File } from "react-feather";
import { useTheme } from "styled-components";
import { Container, DocumentIconWrapper } from "./styles";
import { DocumentQuoteProps } from "./types";

export function DocumentQuote({ quote }: DocumentQuoteProps) {
  const theme = useTheme();

  return (
    <Container>
      <DocumentIconWrapper>
        <File color={theme.colors.muted} size="16" />
      </DocumentIconWrapper>
      <p>Documento anexado</p>
    </Container>
  );
}
