import { useEffect, useState } from "react";
import { ArrowRightCircle, Plus, User } from "react-feather";
import { useTheme } from "styled-components";
import { MessageTitle } from "styles/MessageTitle";
import { ContactCard, ContactCardWithWhatsappPhone } from "types/ContactCard";
import { AddContactIconWrapper, Contact, ContactIconWrapper, Container, Name } from "./styles";
import { ContactMessageProps } from "./types";
import ReactTooltip from "react-tooltip";
import { NewConversationModal } from "components/NewConversationModal";
import { ContactData } from "components/NewConversationModal/types";

export function ContactMessage({ message }: ContactMessageProps) {
  const theme = useTheme();
  const [parsedInfos, setParsedInfos] = useState<ContactCard[]>([]);

  const [isNewConverastionModalOpen, setIsNewConverastionModalOpen] = useState(false);
  const [contactToCreateData, setContactToCreateData] = useState<ContactData>({} as ContactData);

  useEffect(() => {
    if (message.body) {
      const cards = message.body as ContactCard[];
      setParsedInfos(Array.isArray(cards) ? cards : [cards]);
    }
  }, [message.body]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  function handleOpenNewConversationModal() {
    setIsNewConverastionModalOpen(true);
  }

  function handleCloseNewConversationModal() {
    setIsNewConverastionModalOpen(false);
  }

  function handleAddContact(contact: ContactCardWithWhatsappPhone) {
    setContactToCreateData({
      name: contact.displayName,
      phone_number: contact.whatsappPhone,
    });

    handleOpenNewConversationModal();
  }

  return (
    <>
      <Container>
        {message.is_forwarded && (
          <MessageTitle spaced={false}>
            <ArrowRightCircle size="18" color={theme.colors.text} />
            Encaminhada
          </MessageTitle>
        )}

        {parsedInfos.map(contact => {
          if (!contact.vCard) {
            return null;
          }

          const contactWithWhatsappPhone = {
            ...contact,
            whatsappPhone: contact.phones[0],
          };

          return (
            <Contact key={contact.displayName}>
              <ContactIconWrapper>
                <User color={theme.colors.muted} size="24" />
              </ContactIconWrapper>
              <div>
                <Name>{contact.displayName}</Name>
                <span>{contact.phones ?? 'N/A'}</span>
              </div>
              <AddContactIconWrapper
                data-tip="Adicionar contato"
                onClick={() => handleAddContact(contactWithWhatsappPhone)}
              >
                <Plus color={theme.colors.buttonText} size="18" />
              </AddContactIconWrapper>
            </Contact>
          )
        })}
      </Container>

      <NewConversationModal
        isOpen={isNewConverastionModalOpen}
        onRequestClose={handleCloseNewConversationModal}
        contactData={contactToCreateData}
      />
    </>
  );
}
