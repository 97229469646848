import { Info } from "react-feather";
import { useTheme } from "styled-components";
import { Container } from "./styles";
import { CallLogQuoteProps } from "./types";

export function CallLogQuote({ quote }: CallLogQuoteProps) {
  const theme = useTheme();

  return (
    <Container>
      <Info color={theme.colors.accent} size="16" />
      Tentativa de chamada realizada.
    </Container>
  );
}
