import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: .3rem 1.25rem;
  bottom: 0;
  border-top: 1px solid ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.text};
  display: flex;
  align-items: center;
  gap: 1rem;
  max-height: 6rem;
  overflow-y: scroll;
`;

export const CancelReply = styled.div`
  cursor: pointer;
`;
