import * as Yup from 'yup';

export function macroValidator() {
  return Yup.object().shape({
    title: Yup
      .string()
      .required('Por favor, informe um título válido para prosseguir.'),
    message: Yup
      .string()
      .required('Por favor, informe uma mensagem válida para prosseguir.'),
  });
}
