import { useMemo } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import { ActionMeta } from "react-select";
import { CustomSelect } from "styles/FormControl";
import { InputWrapper } from "styles/InputWrapper";
import { SelectProps } from "./types";

export type Option = { value: string; label: string };
type OptionsType = Option[];

export function isOption(obj: unknown): obj is Option {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    obj &&
    'value' in obj &&
    'label' in obj
  );
}

export function Select({
  name,
  label,
  id,
  placeholder,
  icons,
  control,
  config,
  disabled,
  onClear,
  onSelect,
  ...props
}: SelectProps) {
  const { setValue } = useFormContext();

  const { field } = useController({
    name,
    control,
  });

  function handleChange(newValue: Option, { action }: ActionMeta<unknown>) {
    if (!field) return;

    if (action === 'clear') {
      setValue(name, undefined);
      if (onClear) onClear();
      return;
    }

    if (isOption(newValue)) {
      setValue(name, newValue.value);
      if (onSelect) onSelect(newValue);
    }
  }

  const value = useMemo(() => {
    return (config.options as OptionsType)?.find(
      (option) => isOption(option) && option.value === field.value,
    );
  }, [config.options, field.value]);

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}

      <InputWrapper>
        {icons}
        <Controller
          control={control}
          name={name}
          render={({ fieldState }) => (
            <>
              <CustomSelect
                id={id}
                hasError={!!fieldState.error}
                classNamePrefix="react-select"
                loadingMessage={() => 'Carregando...'}
                placeholder={placeholder}
                {...config}
                isDisabled={disabled}
                value={value}
                onChange={handleChange}
              />

              {fieldState.error && <span className="input-error-message">{fieldState.error.message}</span>}
            </>
          )}
          {...props}
        />
      </InputWrapper>
    </>
  );
}
