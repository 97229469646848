export function isEmpty(object: object) {
  return Object.keys(object).length === 0;
}

export function escapeHtml(text: string) {
  const map: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
  };

  return text.replace(/[&<>"]/g, function(m) {
    return map[m];
  });
}
