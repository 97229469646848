import styled, { css } from "styled-components";
import { ButtonProps } from "types/Button";

export const Button = styled.button<ButtonProps>`
  width: ${props => props.fullwidth ? '100%' : 'auto'};
  background: ${props => props.theme.colors.primary};
  border: 0;
  padding: 0 2rem;
  min-height: 3rem;
  color: ${props => props.theme.colors.buttonText};
  border-radius: .25rem;
  transition: filter .2s;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;

  &:hover {
    filter: brightness(1.1);
  }

  ${props => props.dropdown && css`
    &::after {
      content: '';
      margin-top: 0;
      display: inline-block;
      border-top: .3rem solid;
      border-right: .3rem solid transparent;
      border-left: .3rem solid transparent;
      border-bottom: 0;
    }
  `}

  ${props => props.small && css`
    min-height: auto;
    padding: .5rem 1rem;
    font-size: 0.875rem;
  `}

  ${props => props.medium && css`
    min-height: auto;
    padding: .6rem 1.5rem;
    font-size: 0.9375rem;
  `}

  ${props => props.danger && css`
    background: ${props => props.theme.colors.danger};
  `}

  ${props => props.accent && css`
    background: ${props => props.theme.colors.accent};
  `}

  ${props => props.rounded && css`
    border-radius: 2rem;
  `}

  ${props => props.outline && css`
    background: transparent;
    border: 1px solid ${props.danger ? props.theme.colors.danger : props.theme.colors.primary};
    transition: background-color .2s;

    &:hover {
      background: ${props.danger ? props.theme.colors.danger : props.theme.colors.primary};

      svg {
        stroke: ${props => props.theme.colors.buttonText};
      }
    }
  `}
`;
