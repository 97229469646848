import { AxiosError } from "axios";
import { TransferContactData } from "components/TransferContactModal/types";
import { useContacts } from "hooks/useContacts";
import { useCount } from "hooks/utils/useCount";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { Contact } from "types/Contact";
import { axiosError, axiosSuccess, handleRequestError, handleRequestSuccess } from "utils/Request";

type TransferContactMutationProps = {
  contactId: string;
  data: TransferContactData;
}

async function transferContact(contactId: string, data: TransferContactData): Promise<Contact> {
  const { data: transferredContact } = await api.post(`/contacts/${contactId}/transfer`, data)
    .then(axiosSuccess)
    .catch(axiosError);
  return transferredContact;
}

export function useTransferContact() {
  const { selectContact } = useContacts();
  const { revalidateCount } = useCount();

  const queryClient = useQueryClient();

  return useMutation(({ contactId, data }: TransferContactMutationProps) => transferContact(contactId, data), {
    onSuccess: (data: Contact) => {
      handleRequestSuccess(`Contato transferido com sucesso!`);
      queryClient.invalidateQueries('messages');
      selectContact(data);
      revalidateCount();
    },
    onError: (error: AxiosError) => {
      handleRequestError(error, 'Um erro ocorreu ao alterar o responsável deste contato. Por favor, tente novamente!');
    }
  });
}
