import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const MessagesDatetime = styled.span`
  color: ${props => props.theme.colors.text};
  margin: 0.375rem 0 1rem 0;
  text-align: center;
  display: block;
`;

export const Loading = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export const UnreadMessages = styled.span`
  color: ${props => props.theme.colors.accent};
  margin: 0.375rem 0 1rem 0;
  text-align: right;
  line-height: 0.7;
  display: none;

  &:after {
    content: '';
    display: inline-block;
    background: currentColor;
    width: 100%;
    height: 1px;
  }
`;
