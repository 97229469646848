import { ArrowRightCircle } from "react-feather";
import { useTheme } from "styled-components";
import { MessageTitle } from "styles/MessageTitle";
import { formatMessage } from "utils/MessageContent";
import { Caption, Container, Video } from "./styles";
import { VideoMessageProps } from "./types";

export function VideoMessage({ message }: VideoMessageProps) {
  const theme = useTheme();

  return (
    <Container>
      <Video hasCaption={!!message.caption || message.is_forwarded} controls>
        <source src={message.body} type="video/mp4" />
        Seu navegador não suporta mensagens de vídeo.
      </Video>
      {(message.caption || message.is_forwarded) && (
        <Caption>
          {message.is_forwarded && (
            <MessageTitle spaced={!!message.caption}>
              <ArrowRightCircle size="18" color={theme.colors.text} />
              Encaminhada
            </MessageTitle>
          )}
          <span dangerouslySetInnerHTML={{ __html: formatMessage(message.caption ?? '') }} />
        </Caption>
      )}
    </Container>
  );
}
