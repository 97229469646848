// import swal from "sweetalert";
import { DropdownListItem } from "components/Dropdown/styles";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useCategories } from "hooks/fetch/useCategories";
import { useChangeContactCategory } from "hooks/mutations/useChangeContactCategory";
import { useRemoveContactCategory } from "hooks/mutations/useRemoveContactCategory";
import { useContacts } from "hooks/useContacts";
import { MouseEvent } from "react";
import { Edit2, Minus } from "react-feather";
import { useTheme } from "styled-components";
import { Category } from "types/Category";
import { Container, Loading } from "./styles";
import { CategoryListProps } from "./types";

export function CategoryList({ onOpenEditCategoryModal }: CategoryListProps) {
  const theme = useTheme();
  const { selectedContact } = useContacts();

  const { data, isLoading } = useCategories();

  const removeContactCategory = useRemoveContactCategory();

  async function handleRemoveContactCategory() {
    await removeContactCategory.mutateAsync({ contact: selectedContact });
  }

  function handleEditCategory(e: MouseEvent, category: Category) {
    e.stopPropagation();
    onOpenEditCategoryModal(category);
  }

  const changeContactCategory = useChangeContactCategory();

  async function handleChangeContactCategory(category: Category) {
    await changeContactCategory.mutateAsync({ contact: selectedContact, category });
  }

  return (
    <Container>
      {/* <DropdownListItem onClick={onOpenAddCategoryModal} primary>
        <Plus color={theme.colors.primary} size="18" />
        <span>Novo setor</span>
      </DropdownListItem> */}

      <DropdownListItem onClick={handleRemoveContactCategory} danger>
        <Minus color={theme.colors.danger} size="18" />
        <span>Remover setor</span>
      </DropdownListItem>

      {isLoading && (
        <Loading>
          <LoadingSpinner size={15} thickness={1} />
        </Loading>
      )}

      {!isLoading && data && data.map((category) => (
        <DropdownListItem onClick={() => handleChangeContactCategory(category)} key={category.id} title={category.name}>
          <span>{category.name}</span>
          <Edit2 onClick={(e) => handleEditCategory(e, category)} size="18" />
        </DropdownListItem>
      ))}
    </Container>
  );
}
