import { Fragment, useRef, useState } from 'react';
import Modal from 'react-modal';

import { ForwardMessageModalProps, SearchData } from './types';
import { Contact as ContactType } from 'types/Contact';

import { Contact, ContactsWrapper, LoadingIcon, SmallLoading } from './styles';
import { Button } from 'styles/Button';

import closeImg from '../../assets/close.svg';
import { Table } from 'styles/Table';
import { Badge } from 'components/Badge';

import { isEmpty } from 'utils/Misc';
import { ModalTitle } from 'styles/Modal';
import { useContactsToForward } from 'hooks/fetch/useContactsToForward';
import { LoadingSpinner } from 'components/LoadingSpinner';

import avatarImg from '../../assets/avatar.jpg';
import { Muted } from 'styles/Muted';
import { handleRequestError } from 'utils/Request';
import { api } from 'services/api';
import { toast } from 'react-toastify';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { searchValidator } from './validation';
import { Form } from 'styles/Form';
import { InputGroup } from 'styles/InputGroup';
import { Input } from 'components/Form/Input';

const validationSchema = searchValidator();

export function ForwardMessageModal({ isOpen, onRequestClose, messageToForward }: ForwardMessageModalProps) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [contactsListEndpoint, setContactsListEndpoint] = useState('/contacts');

  const { handleSubmit, control } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      search: '',
    },
  });

  const [selectedContact, setSelectedContact] = useState<ContactType>({} as ContactType);
  const [isForwarding, setIsForwarding] = useState(false);

  const { loadMoreRef, query: { data, isFetchingNextPage, isFetching } } = useContactsToForward(contactsListEndpoint);

  async function handleSearch({ search }: SearchData) {
    if (search) {
      setContactsListEndpoint(`/contacts?filter[find]=${search}`);
    } else {
      setContactsListEndpoint('/contacts');
    }
  }

  function handleSelectContact(contact: ContactType) {
    if (contact === selectedContact) {
      return setSelectedContact({} as ContactType);
    }

    return setSelectedContact(contact);
  }

  async function handleForwardMessage() {
    if (!selectedContact) return;

    setIsForwarding(true);

    try {
      await api.post(`/messages/${messageToForward.whatsapp_msg_id}/forward/${selectedContact.id}`);
      toast.success('Mensagem encaminhada com sucesso!');
    } catch (error) {
      handleRequestError(error, 'Ocorreu um erro ao encaminhar a mensagem. Por favor, tente novamente!');
    }

    setIsForwarding(false);

    handleRequestClose();
  }

  function handleRequestClose() {
    setSelectedContact({} as ContactType);
    onRequestClose();
  }

  function handleKeyUp() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      handleSubmit(handleSearch)();
    }, 500);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={handleRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <div>
        <ModalTitle>Encaminhar mensagem</ModalTitle>

        <Form onSubmit={handleSubmit(handleSearch)}>
          <InputGroup>
            <Input
              icons={isFetching && !isFetchingNextPage ? (
                <LoadingIcon>
                  <LoadingSpinner size={15} thickness={1} />
                </LoadingIcon>
              ) : ''}
              control={control}
              type="search"
              id="forwardSearch"
              label="Procurar contato"
              name="search"
              placeholder="Procurar..."
              onKeyUp={handleKeyUp}
              onClick={handleKeyUp}
              fullwidth
            />
          </InputGroup>
        </Form>

        <ContactsWrapper>
          <Table>
            <thead>
              <tr>
                <th>Contato</th>
                <th>Setor</th>
              </tr>
            </thead>
            <tbody>
              {data && data.pages && data.pages.map((page, index) => (
                <Fragment key={index}>
                  {page.data.map((contact) => (
                    <Contact
                      key={contact.id}
                      onClick={() => handleSelectContact(contact)}
                      className={contact === selectedContact ? 'selected' : ''}
                    >
                      <td>
                        <img
                          src={contact.image_url ?? avatarImg}
                          alt={contact.name}
                          loading="lazy"
                          onError={(e) => e.currentTarget.src = avatarImg}
                        />
                        <div>
                          <span>{contact.name}</span>
                        </div>
                      </td>
                      <td>
                        {contact.category?.name ? <Badge>{contact.category?.name}</Badge> : <Muted>N/A</Muted>}
                      </td>
                    </Contact>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </Table>

          <div ref={loadMoreRef} style={{ visibility: 'hidden' }}></div>

          {isFetchingNextPage && (
            <SmallLoading>
              <LoadingSpinner size={25} thickness={2} />
            </SmallLoading>
          )}
        </ContactsWrapper>

        {isForwarding
          ? <Button type="button" disabled fullwidth>Encaminhando...</Button>
          : (
            <Button
              type="button"
              disabled={isEmpty(selectedContact)}
              onClick={handleForwardMessage}
              fullwidth
            >
              {isEmpty(selectedContact)
                ? 'Selecione um contato para encaminhar a mensagem'
                : `Encaminhar para ${selectedContact.name}`
              }
            </Button>
          )
        }
      </div>
    </Modal>
  );
}
