import { LoadingSpinner } from "components/LoadingSpinner";
import { Container } from "./styles";

export function Loading() {
  return (
    <Container>
      <LoadingSpinner size={160} thickness={16} />
      <span>Carregando...</span>
    </Container>
  )
}
