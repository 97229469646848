import { ImageModal } from "components/ImageModal";
import { useState } from "react";
import { ArrowRightCircle } from "react-feather";
import { useTheme } from "styled-components";
import { MessageTitle } from "styles/MessageTitle";
import { formatMessage } from "utils/MessageContent";
import { Caption, Container, Image } from "./styles";
import { ImageMessageProps } from "./types";

export function ImageMessage({ message }: ImageMessageProps) {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const theme = useTheme();

  function handleOpenMessageImageModal() {
    setIsImageModalOpen(true);
  }

  function handleCloseMessageImageModal() {
    setIsImageModalOpen(false);
  }

  return (
    <>
      <Container>
        <Image
          onClick={handleOpenMessageImageModal}
          hasCaption={!!message.caption || message.is_forwarded}
          src={message.body}
          alt={message.caption ?? 'Imagem sem descrição'}
        />
        {(message.caption || message.is_forwarded) && (
          <Caption>
            {message.is_forwarded && (
              <MessageTitle spaced={!!message.caption}>
                <ArrowRightCircle size="18" color={theme.colors.text} />
                Encaminhada
              </MessageTitle>
            )}
            <span dangerouslySetInnerHTML={{ __html: formatMessage(message.caption ?? '') }} />
          </Caption>
        )}
      </Container>

      <ImageModal
        isOpen={isImageModalOpen}
        onRequestClose={handleCloseMessageImageModal}
        imageUrl={message.body}
        caption={message.caption}
      />
    </>
  );
}
