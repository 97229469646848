import { useController } from "react-hook-form";
import { FormControl } from "styles/FormControl";
import { InputWrapper } from "styles/InputWrapper";
import { TextareaProps } from "./types";

export function Textarea({
  name,
  label,
  id,
  placeholder,
  icons,
  control,
  children,
  ...props
}: TextareaProps) {
  const { field, fieldState } = useController({ name, control });

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}

      <InputWrapper>
        {icons}
        <FormControl
          hasError={!!fieldState.error}
          placeholder={placeholder}
          id={id}
          as="textarea"
          {...field} {...props}
        >
          {children}
        </FormControl>
      </InputWrapper>

      {fieldState.error && <span className="input-error-message">{fieldState.error.message}</span>}
    </>
  );
}
