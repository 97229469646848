import { AxiosError } from "axios";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { Category, CreateCategoryData } from "types/Category";
import { handleFormErrors } from "utils/Form";
import { axiosError, axiosSuccess, handleRequestSuccess } from "utils/Request";

type CreateCategoryProps = {
  data: CreateCategoryData;
}

async function createCategory(newData: CreateCategoryData): Promise<Category> {
  const { data } = await api.post('/categories', { ...newData }).then(axiosSuccess).catch(axiosError);
  return data;
}

export function useCreateCategory(errorSetter: UseFormSetError<FieldValues>) {
  const queryClient = useQueryClient();

  return useMutation(({ data }: CreateCategoryProps) => createCategory(data), {
    onSuccess: (data: Category) => {
      handleRequestSuccess(`Setor "${data.name}" criado com sucesso!`);
      queryClient.invalidateQueries('categories');
      queryClient.invalidateQueries('count');
    },
    onError: (error: AxiosError) => {
      handleFormErrors(error, errorSetter);
    }
  });
}
