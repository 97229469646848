import { useState } from "react";

import { CategoryList } from "components/CategoryList";
import { Dropdown } from "components/Dropdown";

import { Button } from "styles/Button";

import { Category } from "types/Category";
import { AddCategoryModal } from "components/AddCategoryModal";
import { EditCategoryModal } from "components/EditCategoryModal";

export function CategoriesButton() {
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);
  const [selectedCategoryToEdit, setSelectedCategoryToEdit] = useState<Category>({} as Category);

  function handleOpenAddCategoryModal() {
    setIsAddCategoryModalOpen(true);
  }

  function handleCloseAddCategoryModal() {
    setIsAddCategoryModalOpen(false);
  }

  function handleOpenEditCategoryModal(Category: Category) {
    setSelectedCategoryToEdit(Category);
    setIsEditCategoryModalOpen(true);
  }

  function handleCloseEditCategoryModal() {
    setSelectedCategoryToEdit({} as Category);
    setIsEditCategoryModalOpen(false);
  }

  return (
    <>
      <Dropdown
        trigger={
          <Button
            type="button"
            medium={!window.matchMedia("(max-width: 1366px)").matches}
            small={window.matchMedia("(max-width: 1366px)").matches}
            dropdown
          >
            Setores
          </Button>
        }
        closeOnClickInside={true}
      >
        <CategoryList
          onOpenAddCategoryModal={handleOpenAddCategoryModal}
          onOpenEditCategoryModal={handleOpenEditCategoryModal}
        />
      </Dropdown>

      <AddCategoryModal
        isOpen={isAddCategoryModalOpen}
        onRequestClose={handleCloseAddCategoryModal}
      />

      <EditCategoryModal
        isOpen={isEditCategoryModalOpen}
        onRequestClose={handleCloseEditCategoryModal}
        categoryToEdit={selectedCategoryToEdit}
      />
    </>
  );
}
