import { useAssumeContact } from "hooks/mutations/useAssumeContact";
import { useContacts } from "hooks/useContacts";
import { Button } from "styles/Button";
import { AssumeContactButtonProps } from "./types";

export function AssumeContactButton({ onAssumeContact }: AssumeContactButtonProps) {
  const assumeContactMutation = useAssumeContact();

  const { selectedContact } = useContacts();

  async function handleAssumeContact() {
    await assumeContactMutation.mutateAsync({ contact: selectedContact });
    onAssumeContact();
  }

  return (
    <Button
      type="button"
      medium={!window.matchMedia("(max-width: 1366px)").matches}
      small={window.matchMedia("(max-width: 1366px)").matches}
      onClick={handleAssumeContact}
    >
      Assumir
    </Button>
  );
}
