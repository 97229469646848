import { lighten } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  padding: 0.3125rem 0;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const ContactIconWrapper = styled.div`
  border-radius: 50%;
  padding: 1rem;
  background: ${props => props.theme.colors.main};
  border: 1px solid ${props => props.theme.colors.borderLight};
  display: flex;
  align-items: center;
`;

export const Contact = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
  margin: 1rem 0;

  & + & {
    padding-top: 1rem;
    border-top: 1px solid ${props => props.theme.colors.borderLight};
  }
`;

export const Name = styled.p`
  font-weight: bold;
`;

export const AddContactIconWrapper = styled.div`
  border-radius: 50%;
  padding: .5rem;
  margin-left: 1rem;
  background: ${props => props.theme.colors.primary};
  border: 1px solid ${props => lighten(0.1, props.theme.colors.primary)};
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: filter .2s;

  &:hover {
    filter: brightness(1.1);
  }
`;
