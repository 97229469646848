import styled, { css } from "styled-components";
import ReactSelect from 'react-select';
import { FormControlProps } from "types/FormControl";
import ContentEditable from "react-contenteditable";

export const FormControl = styled(ContentEditable).withConfig({
  shouldForwardProp: (prop) =>
    !['padding', 'hasError', 'fullwidth', 'small'].includes(prop)
})<FormControlProps>`
  &:not([type="checkbox"]) {
    width: ${props => props.fullwidth ? '100%' : 'auto'};
    padding: ${props => props.padding || '.8rem 1.5rem'};
    border-radius: .25rem;
    border: 1px solid ${props => props.hasError ? props.theme.colors.danger : props.theme.colors.border};
    background: ${props => props.theme.colors.inputBackground};
    color: ${props => props.theme.colors.text};
    font-weight: 400;
    font-size: 1rem;
    white-space: pre-wrap;
    word-break: break-word;

    &::placeholder {
      color: ${props => props.theme.colors.muted};
    }

    &.editableContent {
      max-height: 10rem;
      overflow-y: scroll;
      max-width: 100%;

      ::-webkit-scrollbar {
        display: none;
      }

      &:empty::before {
        content: attr(data-placeholder);
        cursor: text;
        color: ${props => props.theme.colors.muted};
      }
    }

    ${props => props.small && css`
      height: 2rem;
      font-size: 0.825rem;
    `}

    ${props => props.small && !props.padding && css`
      padding: 0 1rem;
    `}
  }

  &[type="search"] {
    &::-webkit-search-cancel-button {
      height: 1em;
      width: 1em;
      border-radius: 50em;
      background-size: contain;
      position: absolute;
      right: 0.625rem;
    }
  }
`;

export const CustomSelect = styled(ReactSelect).withConfig({
  shouldForwardProp: (prop) =>
    !['padding', 'hasError', 'fullwidth', 'small'].includes(prop)
})<FormControlProps>`
  .react-select__placeholder {
    color: ${props => props.theme.colors.muted};
  }

  .react-select__control {
    width: ${props => props.fullwidth ? '100%' : 'auto'};
    padding: ${props => props.padding || '.8rem 1.5rem'};
    border-radius: .25rem;
    border: 1px solid ${props => props.hasError ? props.theme.colors.danger : props.theme.colors.border};
    background: ${props => props.theme.colors.inputBackground};
    color: ${props => props.theme.colors.text};
    font-weight: 400;
    font-size: 1rem;
    white-space: pre-wrap;
    word-break: break-word;

    &:hover {
      border-color: ${props => props.hasError ? props.theme.colors.danger : props.theme.colors.border};
    }

    ${props => props.small && css`
      height: 2rem;
      font-size: 0.825rem;
    `}

    ${props => props.small && !props.padding && css`
      padding: 0 1rem;
    `}
  }

  .react-select__control--is-disabled {
    opacity: 0.5;
  }

  .react-select__input-container {
    color: ${props => props.theme.colors.text};
    margin: 0;
    padding: 0;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__indicator {
    padding: 4px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    background: ${props => props.theme.colors.main};
  }

  .react-select__option {
    cursor: pointer;
    padding: .8rem 1.5rem;

    &--is-focused {
      background: ${props => props.theme.colors.hover};
    }

    &--is-selected {
      background: ${props => props.theme.colors.primary};
    }
  }

  .react-select__single-value {
    color: ${props => props.theme.colors.text};

    .option-icons {
      display: none;
    }
  }

  .react-select__indicator svg {
    width: 10px;
    height: 10px;
  }
`;
