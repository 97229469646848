import styled from "styled-components";
import { InputIcon } from "styles/mixins/InputIcon";
//@ts-ignore
import PhoneInput from "react-phone-number-input/react-hook-form";

export const PhoneInputWithCountry = styled(PhoneInput)`
  .PhoneInputCountry {
    ${InputIcon}
    right: 1rem;
    color: ${props => props.theme.colors.text};
  }
`;
