import { RefObject, useEffect } from "react"

interface UseIntersectionObserverProps {
  root?: RefObject<HTMLElement>;
  target: RefObject<HTMLElement>;
  onIntersect: () => unknown;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean | undefined;
}

export default function useIntersectionObserver({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
}: UseIntersectionObserverProps) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => entry.isIntersecting && onIntersect()),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    )

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.current, enabled])
}
