import { formatMessage } from "utils/MessageContent";
import { Caption, Container, Video } from "./styles";
import { VideoQuoteProps } from "./types";

export function VideoQuote({ quote }: VideoQuoteProps) {
  return (
    <Container>
      <Video controls={false} autoPlay={true} loop muted>
        <source src={quote.body} type="video/mp4" />
        Seu navegador não suporta mensagens de vídeo.
      </Video>
      <Caption dangerouslySetInnerHTML={{ __html: formatMessage(quote.caption ?? 'Mensagem de vídeo') }} />
    </Container>
  );
}
