import { RefObject } from "react";

export function useClickOutside(
  componentRef: RefObject<HTMLDivElement>,
  action: () => void,
  triggerRef: RefObject<HTMLDivElement> | null = null
) {
  function handleHideOnEscape(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      action();
    }
  };

  function handleClickOutside(e: Event) {
    const trigger = e.target as HTMLElement;

    if (
      componentRef.current
      && !componentRef.current.contains(trigger)
    ) {
      if (
        triggerRef
        && triggerRef.current
        && triggerRef.current === trigger.closest('.trigger')
      ) {
        return;
      }
      return action();
    }
  };

  return { handleClickOutside, handleHideOnEscape };
}
