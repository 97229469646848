import Modal from 'react-modal';
import { MacroManageableListProps } from './types';
import closeImg from '../../assets/close.svg';
import { useMacros } from 'hooks/fetch/useMacros';
import { ActionsButtons, MacroListWrapper } from './styles';
import { Table } from 'styles/Table';
import { Button } from 'styles/Button';
import { useDeleteMacro } from 'hooks/mutations/useDeleteMacro';
import { deleteMacro } from 'modules/macros';
import { useTheme } from 'styled-components';
import { Macro } from 'types/Macro';
import { MouseEvent, useState } from 'react';
import { EditMacroModal } from 'components/EditMacroModal';

export function MacroManageableList({ isOpen, onRequestClose }: MacroManageableListProps) {
  const [isEditMacroModalOpen, setIsEditMacroModalOpen] = useState(false);
  const [macroToEdit, setMacroToEdit] = useState<Macro>({} as Macro);

  const { data } = useMacros({
    sort: 'title',
  });

  const theme = useTheme();

  function handleEditMacro(e: MouseEvent, macro: Macro) {
    e.stopPropagation();
    handleOpenEditMacroModal(macro);
  }

  function handleOpenEditMacroModal(macro: Macro) {
    setMacroToEdit(macro);
    setIsEditMacroModalOpen(true);
  }

  function handleCloseEditMacroModal() {
    setMacroToEdit({} as Macro);
    setIsEditMacroModalOpen(false);
  }

  const deleteMacroMutation = useDeleteMacro();

  async function handleDeleteMacro(e: MouseEvent, macro: Macro) {
    e.stopPropagation();

    await deleteMacro({
      mutation: deleteMacroMutation,
      currentTheme: theme,
      macro,
    });
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content big"
      >
        <button
          type="button"
          onClick={onRequestClose}
          className="react-modal-close"
        >
          <img src={closeImg} alt="Fechar modal" loading="lazy" />
        </button>

        <MacroListWrapper>
          <Table>
            <thead>
              <tr>
                <th>Título</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {data && data.map((macro) => (
                <tr key={macro.id}>
                  <td>
                    <span>{macro.title}</span>
                  </td>
                  <td>
                    <ActionsButtons>
                      <Button onClick={(e) => handleEditMacro(e, macro)} small>Editar</Button>
                      <Button onClick={(e) => handleDeleteMacro(e, macro)} small danger>Excluir</Button>
                    </ActionsButtons>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </MacroListWrapper>
      </Modal>

      <EditMacroModal
        isOpen={isEditMacroModalOpen}
        onRequestClose={handleCloseEditMacroModal}
        macroToEdit={macroToEdit}
      />
    </>
  );
}
