import { AxiosError } from "axios";
import { useContacts } from "hooks/useContacts";
import { useCount } from "hooks/utils/useCount";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation } from "react-query";
import { api } from "services/api";
import { Contact, EditContactData } from "types/Contact";
import { handleFormErrors } from "utils/Form";
import { axiosError, axiosSuccess, handleRequestSuccess } from "utils/Request";

type EditContactProps = {
  contactToEdit: Contact;
  data: EditContactData;
}

async function editContact(contact: Contact, newData: EditContactData): Promise<Contact> {
  const { data } = await api.put(`/contacts/${contact.id}`, { ...newData }).then(axiosSuccess).catch(axiosError);
  return data;
}

export function useEditContact(errorSetter: UseFormSetError<FieldValues>) {
  const { selectContact } = useContacts();
  const { revalidateCount } = useCount();

  return useMutation(({ contactToEdit, data }: EditContactProps) => editContact(contactToEdit, data), {
    onSuccess: (data: Contact) => {
      handleRequestSuccess(`Contato "${data.name}" editado com sucesso!`);
      selectContact(data);
      revalidateCount();
    },
    onError: (error: AxiosError) => {
      handleFormErrors(error, errorSetter)
    }
  });
}
