import styled, { css } from "styled-components";
import { EllipsedTextProps } from "./types";

export const Container = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EllipsedText = styled.span<EllipsedTextProps>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props => props.maxWidth && css`
    max-width: ${props.maxWidth}px;
  `}
`;
