import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  border-radius: .25rem;
  background: ${props => props.theme.colors.accent};
  color: ${props => props.theme.colors.highlightedText};
  padding: .25rem .5rem;
  font-size: 0.875rem;
`;
