import { AxiosError } from "axios";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { EditMacroData, Macro } from "types/Macro";
import { handleFormErrors } from "utils/Form";
import { axiosError, axiosSuccess, handleRequestSuccess } from "utils/Request";

type EditMacroProps = {
  macroToEdit: Macro;
  data: EditMacroData;
}

async function editMacro(macro: Macro, newData: EditMacroData): Promise<Macro> {
  const { data } = await api.put(`/macros/${macro.id}`, { ...newData }).then(axiosSuccess).catch(axiosError);
  return data;
}

export function useEditMacro(errorSetter: UseFormSetError<FieldValues>) {
  const queryClient = useQueryClient();

  return useMutation(({ macroToEdit, data }: EditMacroProps) => editMacro(macroToEdit, data), {
    onSuccess: (data: Macro) => {
      handleRequestSuccess(`Macro "${data.title}" editado com sucesso!`);
      queryClient.invalidateQueries('macros');
    },
    onError: (error: AxiosError) => {
      handleFormErrors(error, errorSetter);
    }
  });
}
