import styled, { css } from "styled-components";
import { ModalTitleProps } from "types/ModalTitle";

export const ModalTitle = styled.h2<ModalTitleProps>`
  color: ${props => props.theme.colors.text};
  font-size: 1.5rem;

  ${props => !props.noSpacing && css`
    margin-bottom: 2rem;
  `}
`;
