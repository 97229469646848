import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    flex: 1;
  }
`;

export const MessageCount = styled.div`
  display: flex;
  gap: 5px;
`;

export const ContactsWithNewMessages = styled.div`
  color: ${props => props.theme.colors.primary};
`;

export const ContactsCount = styled.div`
  color: ${props => props.theme.colors.text};
`;
