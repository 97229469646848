import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  padding: 1.25rem 1.25rem 0 1.25rem;
  border-right: 1px solid ${props => props.theme.colors.border};
  overflow-y: scroll;
  border-spacing: 0 .5rem;
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;
