import { AxiosError } from "axios";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { Category, EditCategoryData } from "types/Category";
import { handleFormErrors } from "utils/Form";
import { axiosError, axiosSuccess, handleRequestSuccess } from "utils/Request";

type EditCategoryProps = {
  categoryToEdit: Category;
  data: EditCategoryData;
}

async function editCategory(category: Category, newData: EditCategoryData) {
  const { data } = await api.put(`/categories/${category.id}`, { ...newData }).then(axiosSuccess).catch(axiosError);
  return data;
}

export function useEditCategory(errorSetter: UseFormSetError<FieldValues>) {
  const queryClient = useQueryClient();

  return useMutation(({ categoryToEdit, data }: EditCategoryProps) => editCategory(categoryToEdit, data), {
    onSuccess: (data: Category) => {
      handleRequestSuccess(`Setor "${data.name}" editado com sucesso!`);
      queryClient.invalidateQueries('categories');
      queryClient.invalidateQueries('count');
    },
    onError: (error: AxiosError) => {
      handleFormErrors(error, errorSetter);
    }
  });
}
