import { useMessages } from "hooks/fetch/useMessages";
import { useContacts } from "hooks/useContacts";
import { createContext, useState } from "react";
import { ChatMessage, MessagesContextData, MessagesProviderProps } from "types/ChatMessage";

export const MessagesContext = createContext({} as MessagesContextData);

export function MessagesProvider({ children, perPage }: MessagesProviderProps) {
  const { selectedContact } = useContacts();

  const messagesQuery = useMessages(selectedContact.id, perPage);

  const [selectedQuotedMessage, setSelectedQuotedMessage] = useState<ChatMessage>({} as ChatMessage);
  const [isRefetchingMessages, setIsRefetchingMessages] = useState(false);
  const [previewMessages, setPreviewMessages] = useState<ChatMessage[]>([]);

  return (
    <MessagesContext.Provider value={{
      messagesQuery,
      selectedQuotedMessage,
      setSelectedQuotedMessage,
      isRefetchingMessages,
      setIsRefetchingMessages,
      previewMessages,
      setPreviewMessages,
    }}>
      {children}
    </MessagesContext.Provider>
  )
}
