import { MessageContainer } from 'styles/MessageContainer';
import { DeletedText } from './styles';

export function DeletedMessage() {
  return (
    <MessageContainer>
      <DeletedText>Mensagem excluída</DeletedText>
    </MessageContainer>
  );
}
