import Modal from 'react-modal';
import { Input } from 'components/Form/Input';
import { Button } from 'styles/Button';
import { InputGroup } from 'styles/InputGroup';
import closeImg from '../../assets/close.svg';
import { Form } from 'styles/Form';
import { NewConversationData, NewConversationModalProps } from './types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { newConversationValidator } from './validation';
import { FieldValues } from 'react-hook-form';
import { ModalTitle } from 'styles/Modal';
import { useStartNewConversation } from 'hooks/mutations/useStartNewConversation';
import { handleRequestError } from 'utils/Request';
import { api } from 'services/api';
import { isEmpty } from 'utils/Misc';
import { Contact } from 'types/Contact';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useContacts } from 'hooks/useContacts';
import { PhoneInput } from 'components/Form/PhoneInput';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useTheme } from 'styled-components';

const validationSchema = newConversationValidator();

export function NewConversationModal({ isOpen, onRequestClose, contactData }: NewConversationModalProps) {
  const { selectContact } = useContacts();

  const theme = useTheme();

  const { handleSubmit, control, setValue, reset, setError, formState: { isSubmitting } } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      phone_number: '',
    },
  });

  useEffect(() => {
    if (isEmpty(contactData ?? {})) return;

    setValue('name', contactData?.name);
    setValue('phone_number', contactData?.phone_number);
    setValue('user_company_id', contactData?.user_company_id);
  }, [contactData, setValue]);

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  const startConversation = useStartNewConversation(setError);

  const ReactSwal = withReactContent(Swal);

  async function shouldCreateNewContact(contact: Contact) {
    return await ReactSwal.fire({
      title: 'Um contato semelhante foi encontrado!',
      html: <span>O contato <b>{contact.name} ({formatPhoneNumberIntl(`+${contact.phone_number}`)})</b> é semelhante à este contato. Deseja cadastrar mesmo assim?</span>,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Forçar cadastro',
      confirmButtonColor: theme.colors.danger,
      reverseButtons: true,
      showDenyButton: true,
      denyButtonText: 'Selecionar contato',
      denyButtonColor: theme.colors.primary,
    });
  }

  async function handleCreateNewConversation({ name, phone_number, user_company_id }: NewConversationData) {
    let willCreate;
    let existingContact;

    try {
      const response = await api.post('/check/contacts', { name, phone_number, user_company_id });

      if (!isEmpty(response.data)) {
        existingContact = response.data;
        willCreate = await shouldCreateNewContact(existingContact);
      }
    } catch (error) {
      handleRequestError(error);
    }

    // Modal apareceu?
    if (willCreate) {
      // Clicou pra selecionar contato
      if (willCreate?.isDenied) {
        handleRequestClose();
        return selectContact(existingContact);
      }

      // Clicou pra forçar cadastro
      if (willCreate?.isConfirmed) {
        handleRequestClose();
        return await startConversation.mutateAsync({ data: { name, phone_number, user_company_id } });
      }

      // Clicou em cancelar ou só fechou o modal
      return;
    }

    // Clicou pra iniciar conversa e modal não apareceu
    await startConversation.mutateAsync({ data: { name, phone_number, user_company_id } });
    handleRequestClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={handleRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <div>
        <ModalTitle>Iniciar nova conversa</ModalTitle>

        <Form onSubmit={handleSubmit(handleCreateNewConversation)}>
          <InputGroup>
            <Input
              control={control}
              type="text"
              name="name"
              id="name"
              label="Nome"
              placeholder="Contato"
              fullwidth
            />
          </InputGroup>
          <InputGroup>
            <PhoneInput
              control={control}
              name="phone_number"
              id="phone_number"
              label="Telefone"
              placeholder="Número de telefone"
              padding=".8rem 5rem .8rem 1.5rem"
              fullwidth
              options={{
                defaultCountry: 'BR',
                countryCallingCodeEditable: true,
                withCountryCallingCode: true,
              }}
            />
          </InputGroup>
          <InputGroup>
            <Input
              control={control}
              type="text"
              name="user_company_id"
              id="user_company_id"
              label="UUID do usuário no sistema"
              placeholder="12345678"
              padding=".8rem 5rem .8rem 1.5rem"
              fullwidth
            />
          </InputGroup>
          <InputGroup>
            <Button type="submit" fullwidth disabled={isSubmitting}>
              {isSubmitting ? 'Carregando...' : 'Iniciar conversa'}
            </Button>
          </InputGroup>
        </Form>
      </div>
    </Modal>
  );
}
