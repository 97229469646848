import styled, { keyframes } from "styled-components";
import { LoadingSpinnerContainerProps } from "./types";

const spin = keyframes`
  to { transform: rotate(360deg); }
`;

export const Container = styled.div<LoadingSpinnerContainerProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: ${props => props.thickness}px solid ${props => props.theme.colors.spinner};
  border-left-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;
