import styled, { css } from "styled-components";
import { DraggingProps } from "../ChatSection/types";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  height: 4rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

export const ChatBox = styled.div`
  background: ${props => props.theme.colors.main};
  flex: 1 0 0;
  display: flex;
  flex-direction: column-reverse;
  padding: 1.25rem;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: filter .2s;
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.main};
`;

export const Buttons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Dragging = styled.div<DraggingProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${props => props.theme.colors.main};
  border: 5px dashed ${props => props.theme.colors.primary};

  h3 {
    color: ${props => props.theme.colors.text};
    font-size: 2.25rem;
    padding: 2rem 0;
  }

  ${props => props.danger && css`
    border: 5px dashed ${props => props.theme.colors.danger};
  `}
`;

export const SendFileInput = styled.input`
  display: none;
`;
