import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: background-color .2s;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width: 1366px) {
      font-size: 87.5%; // 14px
    }
  }

  body {
    background: ${props => props.theme.colors.background};
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.background};
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.contentBackground};
  }

  body, input, textarea, button {
    font-family: 'Source Sans Pro', 'Arial', sans-serif;
    font-weight: 400;
    color: ${props => props.theme.colors.text};
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  a {
    color: ${props => props.theme.colors.primary};
  }

  a p {
    color: ${props => props.theme.colors.text};
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: .6;
    cursor: not-allowed;
  }

  .hidden {
    display: none;
  }

  /* ::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primary};
  } */

  .react-modal-overlay {
    background: rgba(0, 0, 0, .5);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-modal-content {
    width: 100%;
    max-width: 576px;
    overflow-y: scroll;
    background: ${props => props.theme.colors.contentBackground};
    padding: 3rem;
    position: relative;
    border-radius: .25rem;

    &.fixed-height {
      overflow-y: unset;
    }

    &.big {
      max-width: 1000px;
      min-height: 470px;
    }
  }

  .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;

    transition: filter .2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  .react-image-modal {
    max-width: 900px;
    border-radius: 1rem;
    overflow: hidden;
  }

  .react-close-image-modal {
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    border: 0;

    img {
      width: 20px;
    }
  }

  .ReactModal__Content {
    opacity: 0;
    transform: scale(0.9);
    transition: all .2s ease-in-out;
  }

  .ReactModal__Content--after-open {
    opacity: 1;
    transform: scale(1);
  }

  .text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .swal2-modal {
    background: ${props => props.theme.colors.contentBackground};

    .swal2-title {
      color: ${props => props.theme.colors.text};
    }

    .swal2-html-container {
      color: ${props => props.theme.colors.text};
      text-align: center;
    }

    .swal2-button--danger {
      background: ${props => props.theme.colors.danger};
    }

    .swal2-button--success {
      background: ${props => props.theme.colors.success};

      &:hover {
        background: ${props => props.theme.colors.success};
      }
    }
  }
`
