export function strToTime(time: string) {
  return new Intl.DateTimeFormat('pt-BR', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(time));
}

export function strToDate(time: string) {
  return new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric', month: 'numeric', day: 'numeric',
  }).format(
    new Date(time)
  );
}

export function strToDateTime(time: string) {
  return new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
    hour12: false,
  }).format(
    new Date(time)
  );
}
