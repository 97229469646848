import { useController } from "react-hook-form";
import { InputWrapper } from 'styles/InputWrapper';
import { FormControl } from 'styles/FormControl';

import 'react-phone-number-input/style.css';
import { CheckboxProps } from "./types";

export function Checkbox({
  name,
  label,
  id,
  control,
  ...props
}: CheckboxProps) {
  const { field, fieldState } = useController({ name, control });

  return (
    <>
      <InputWrapper type="checkbox">
        <FormControl
          type="checkbox"
          id={id}
          as="input"
          checked={field.value}
          hasError={!!fieldState.error}
          {...field} {...props}
        />
      </InputWrapper>

      {label && id && <label htmlFor={id}>{label}</label>}

      {fieldState.error && <span className="input-error-message">{fieldState.error.message}</span>}
    </>
  );
};
