import { formatMessage } from 'utils/MessageContent';
import { Container } from './styles';
import { ReactionMessageProps } from './types';

export function ReactionMessage({ message }: ReactionMessageProps) {
  return (
    <Container>
      <span dangerouslySetInnerHTML={{ __html: formatMessage(message.body) }} />
    </Container>
  );
}
