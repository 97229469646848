import * as Yup from 'yup';

export function transferContactValidator() {
  return Yup.object().shape({
    attendance_content_id: Yup.string().required('Informe o assunto.'),
    channel_id: Yup.string().required('Informe o canal.'),
    category_id: Yup.string().required('Informe o setor.'),
    annotation: Yup.string(),
  });
}
