import sanitizeHtml from "sanitize-html";

export function formatMessage(content: string) {
  if (!content) return '';

  const bold = /\*((?<![a-zA-Z]\*)(.*?)(?=\*(?![a-zA-Z])))\*/g // Example: This is a *message*
  const italic = /_((?<![a-zA-Z]_)(.*?)(?=_(?![a-zA-Z])))_/g // Example: This is a _message_
  const stroke = /~((?<![a-zA-Z]~)(.*?)(?=~(?![a-zA-Z])))~/g // Example: This is a ~message~
  const code = /`{3}(`*[^`\n]+`*)`{3}/g;

  let message = content
    .replace(bold, '<b>$1</b>')
    .replace(italic, '<i>$1</i>')
    .replace(stroke, '<s>$1</s>')
    .replace(code, '<code>$1</code>');

  message = sanitizeHtml(message, {
    allowedTags: ['b', 'i', 's', 'code', 'span'],
    transformTags: {
      'img': function (_, attribs) {
        return {
          tagName: 'span',
          text: attribs['src'],
          attribs: {},
        }
      }
    },
    allowedAttributes: {
      'a': [ 'href' ],
    },
  });

  return message;
}

export function unformatMessage(content: string) {
  return sanitizeHtml(content, {
    allowedTags: [],
  });
}
