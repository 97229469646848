import { LoadingSpinner } from "components/LoadingSpinner";
import { SidenavLink } from "components/SidenavLink";
import { useAuth } from "hooks/useAuth";
import { useLocation } from "react-router";
import { Loading } from "./styles";
import { ResponsibleMenuListProps } from "./types";

export function ResponsibleMenuList({ responsibles, isLoading }: ResponsibleMenuListProps) {
  const { search } = useLocation();
  const { user } = useAuth();

  return (
    <>
      {isLoading && (
        <Loading>
          <LoadingSpinner size={20} thickness={2} />
        </Loading>
      )}

      {!isLoading && responsibles && responsibles.map((responsible) => {
        if (user && user.id !== responsible.id) {
          return (
            <SidenavLink
              key={responsible.id}
              to={{ pathname: `/contatos/responsavel/${responsible.id}`, search }}
              contactsWithNewMessages={responsible.contacts_with_new_messages_count}
              contactsCount={responsible.all_opened_contacts_count}
            >
              {responsible.name}
            </SidenavLink>
          )
        }

        return null;
      })}
    </>
  );
}
