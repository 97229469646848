import { rgba } from "polished";

const light = {
  title: 'light',
  colors: {
    primary: '#0051bb',
    accent: '#f58f0e',

    danger: '#df4740',
    success: '#45df40',

    annotationBorder: '#f58f0e',
    annotation: '#f7e3c8',
    automaticAnnotation: '#fffeee',
    automaticAnnotationText: '#363f5f',

    text: '#363f5f',
    muted: '#969cb3',

    hover: '#fafafa',
    hoveredText: '#000',

    main: '#fafafa',

    selected: '#ebebeb',

    border: '#d7d7d7',
    borderLight: '#f0f0f0',

    background: '#dadbd4',

    inputBackground: 'transparent',

    defaultMessage: '#fff',
    messageFromMe: '#eef5ff',

    messageOverlays: '#999',
    messageText: '#333',
    deletedMessage: '#999',

    highlightedText: '#fff',
    dropdownMenu: '#fff',
    modalCaption: '#fff',
    contentBackground: '#fff',
    buttonText: '#fff',

    spinner: rgba(0, 0, 0, 0.1),

    switchHandler: '#ced2de',

    quote: '#f1f1f1',
    quoteFromMe: '#0051bb',
    quoteFromContact: '#969cb3',
  },
  styles: {
    smallShadow: '0 1px 1px #ccc',
  },
}

export default light;
