import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "styled-components";

export function ContactProfileSkeleton() {
  const theme = useTheme();

  return (
    <SkeletonTheme
      color={theme.colors.defaultMessage}
      highlightColor={theme.colors.selected}
    >
      <div style={{ display: 'flex', gap: '1rem' }}>
        <Skeleton
          style={{ borderRadius: '50%' }}
          width={40}
          height={40}
        />
        <div>
          <Skeleton
            style={{ display: 'block', marginBottom: '5px' }}
            width={150}
            height={15}
          />
          <Skeleton
            style={{ display: 'block' }}
            width={150}
            height={15}
          />
        </div>
      </div>
    </SkeletonTheme>
  );
}
