import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import Modal from 'react-modal';

import { AddMacroModalProps } from "./types";

import { macroValidator } from "./validation";

import { Input } from "components/Form/Input";
import { useCreateMacro } from 'hooks/mutations/useCreateMacro';
import { useEffect } from 'react';
import { Button } from "styles/Button";
import { Form } from "styles/Form";
import { InputGroup } from "styles/InputGroup";
import { MacroTextarea } from 'styles/MacroTextarea';
import { ModalTitle } from "styles/Modal";
import { CreateMacroData } from 'types/Macro';
import closeImg from '../../assets/close.svg';

const validationSchema = macroValidator();

export function AddMacroModal({ isOpen, onRequestClose, macroToAdd }: AddMacroModalProps) {
  const { handleSubmit, setValue, setError, control, reset, formState: { isSubmitting } } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
      message: '',
    },
  });

  useEffect(() => {
    setValue('title', macroToAdd ?? '');
  }, [setValue, macroToAdd]);

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  const createMacro = useCreateMacro(setError);

  async function handleCreateNewMacro({ title, message }: CreateMacroData) {
    await createMacro.mutateAsync({ data: { title, message } });
    handleRequestClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content big"
    >
      <button
        type="button"
        onClick={handleRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <div>
        <ModalTitle>Criar macro</ModalTitle>

        <Form onSubmit={handleSubmit(handleCreateNewMacro)}>
          <InputGroup>
            <Input
              control={control}
              type="text"
              name="title"
              id="title"
              label="Título"
              placeholder="Título do macro"
              maxLength={255}
              fullwidth
            />
          </InputGroup>
          <InputGroup>
            <MacroTextarea
              control={control}
              name="message"
              id="macroMessage"
              label="Mensagem"
              placeholder="Mensagem do macro"
              rows={4}
              fullwidth
            />
          </InputGroup>
          <InputGroup>
            <Button type="submit" fullwidth disabled={isSubmitting}>
              {isSubmitting ? 'Carregando...' : 'Criar macro'}
            </Button>
          </InputGroup>
        </Form>
      </div>
    </Modal>
  );
}
