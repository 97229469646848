import { useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "services/api";
import { Channel } from "types/Channel";
import { handleRequestError } from "utils/Request";

type ChannelsParams = {}

export async function fetchChannels(params?: ChannelsParams): Promise<Channel[]> {
  const { data } = await api.get('/tecimob/channels', { params });
  return data;
}

export function useChannels(params?: ChannelsParams) {
  const query = useQuery('channels', () => fetchChannels(params));

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Ocorreu um erro ao listar os canais. Por favor, tente novamente!');
  }, [query.isError, query.error]);

  return query;
}
