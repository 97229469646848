import { CategoryMenuList } from 'components/CategoryMenuList';
import { LoggedUser } from 'components/LoggedUser';
import { NewConversationButton } from 'components/NewConversationButton';
import { ResponsibleMenuList } from 'components/ResponsibleMenuList';
import { Search } from 'components/Search';
import { useAuth } from 'hooks/useAuth';
import { useLocation } from 'react-router';
import { Container, Heeader, MenuItems, MenuSection, Nav } from './styles';
import { SidenavLink } from 'components/SidenavLink';
import { StatusAlert } from 'components/StatusAlert';
import { useSocket } from 'hooks/useSocket';
import { MouseEvent, useEffect, useState } from 'react';
import { api } from 'services/api';
import { useCountContacts } from 'hooks/fetch/useCountContacts';
import { isAxiosError } from 'utils/Request';
import Swal from 'sweetalert2';
import { useTheme } from 'styled-components';

export function Sidenav() {
  const { search } = useLocation();
  const { user } = useAuth();

  const theme = useTheme();

  const { data: count, isLoading } = useCountContacts();

  const { error: socketError } = useSocket();

  const [whatsAppConnectionError, setWhatsAppConnectionError] = useState<string | null>(null);
  const [searchUrl, setSearchUrl] = useState('');

  async function checkWhatsAppStatus() {
    try {
      await api.get('/chat-api/status');
      setWhatsAppConnectionError(null);
    } catch (error: unknown) {
      if (isAxiosError(error) && error.response && error.response.data.message) {
        setWhatsAppConnectionError(error.response.data.message);
      }
    }
  }

  useEffect(() => {
    checkWhatsAppStatus();
    setInterval(checkWhatsAppStatus, 60 * 2 * 1000); // every 2 minutes
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const urlMain = getUrlMain(url);

    const validUrl = url.startsWith(`${urlMain}contatos/procurar/`);
    if (validUrl) {
      const lastValue = getLastValueUrl(url);
      setSearchUrl(lastValue);
    }
  }, []);

  function getLastValueUrl(urlData: string) {
    const parts = urlData.split('/');
    return parts[parts.length - 1];
  };

  function getUrlMain(urlData: string) {
    const index = urlData.indexOf('contatos/procurar/');

    if (index !== -1) {
      return urlData.substring(0, index);
    } else {
      return '';
    }
  }

  function handleReload(e: MouseEvent) {
    e.preventDefault();
    window.location.reload();
  }

  async function handleTryToConnectAgain(e: MouseEvent) {
    e.preventDefault();

    Swal.fire({
      text: 'Tentando reconexão...',
    });

    await checkWhatsAppStatus()
      .then(() => Swal.fire({
        icon: 'info',
        text: 'Conexão reestabelecida com sucesso.',
        timer: 3000,
      }))
      .catch(() => Swal.fire({
        icon: 'error',
        text: 'Erro ao reestabeler conexão.',
        timer: 3000,
        confirmButtonText: theme.colors.danger,
      }))
      .finally(() => {
        Swal?.close();
      });
  }

  return (
    <Container>
      <Heeader>
        <LoggedUser />
        <Search valueDataUrl={searchUrl} />
        <NewConversationButton />
        {socketError && (
          <StatusAlert>
            Ocorreu um erro ao estabelecer a sua conexão. <a href="./" onClick={handleReload}>Recarregue a página para tentar novamente.</a>
          </StatusAlert>
        )}
        {whatsAppConnectionError && (
          <StatusAlert>
            {whatsAppConnectionError} <a href="./" onClick={handleTryToConnectAgain}>Tentar novamente.</a>
          </StatusAlert>
        )}
      </Heeader>

      <Nav>
        <MenuSection>
          <MenuItems>
            <SidenavLink
              to={{ pathname: `/contatos/novos`, search }}
              contactsWithNewMessages={count && count.news.contacts_with_new_messages_count}
              contactsCount={count && count.news.all_opened_contacts_count}
            >
              Novos
            </SidenavLink>
            <SidenavLink
              to={{ pathname: `/contatos/todos-novos`, search }}
              contactsWithNewMessages={count && count.contacts_with_new_messages_count}
            >
              Novos (todos)
            </SidenavLink>
            <SidenavLink
              to={{ pathname: '/contatos/arquivados', search }}
            >
              Arquivados
            </SidenavLink>
            {user && (
              <SidenavLink
                to={{ pathname: '/contatos/meus-contatos', search }}
                contactsWithNewMessages={count && count.me.user.contacts_with_new_messages_count}
                contactsCount={count && count.me.user.all_opened_contacts_count}
              >
                {user.name}
              </SidenavLink>
            )}
          </MenuItems>
        </MenuSection>

        <MenuSection>
          <span>Setores</span>
          <MenuItems>
            <CategoryMenuList
              categories={count && count.categories}
              isLoading={isLoading}
            />
          </MenuItems>
        </MenuSection>

        <MenuSection>
          <span>Responsáveis</span>
          <MenuItems>
            <ResponsibleMenuList
              responsibles={count && count.users}
              isLoading={isLoading}
            />
          </MenuItems>
        </MenuSection>
      </Nav>
    </Container>
  );
}
