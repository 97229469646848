import styled from "styled-components";
import { Settings } from "react-feather";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text};

  img {
    border-radius: 50%;
    width: 40px;
  }

  span {
    margin: 0 .5rem;
    font-size: 0.875rem;
  }
`;

export const Logout = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.danger};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SettingsIcon = styled(Settings)`
  cursor: pointer;
`;
