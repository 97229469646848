import { Container, EditIcon, Infos, UserName, UserPhone } from "./styles";
import { ContactProfileProps } from "./types";

import { EditContactModal } from "components/EditContactModal";
import { MouseEvent, useState } from "react";
import { useTheme } from "styled-components";

import { ContactInfoModal } from "components/ContactInfoModal";
import { ImageModal } from "components/ImageModal";
import { TextEllipsis } from "components/TextEllipsis";
import { useContacts } from "hooks/useContacts";
import { Check } from "react-feather";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { api } from "services/api";
import avatarImg from '../../assets/avatar.jpg';
import { ContactProfileSkeleton } from "./skeleton";
import { handleRequestSuccess } from "utils/Request";
import { handleRequestError } from "utils/Request";

export function ContactProfile({ user, isSelectingContact }: ContactProfileProps) {
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [isContactInfoModalOpen, setIsContactInfoModalOpen] = useState(false);
  const [isProfilePictureModalOpen, setIsProfilePictureModalOpen] = useState(false);

  const { selectContact } = useContacts();

  function handleOpenEditContactModal() {
    setIsEditContactModalOpen(true);
  }

  function handleCloseEditContactModal() {
    setIsEditContactModalOpen(false);
  }

  function handleOpenContactInfoModal(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    setIsContactInfoModalOpen(true);
  }

  function handleCloseContactInfoModal() {
    setIsContactInfoModalOpen(false);
  }

  function handleCloseProfilePictureModal() {
    setIsProfilePictureModalOpen(false);
  }

  function handleOpenProfilePictureModal() {
    setIsProfilePictureModalOpen(true);
  }

  function handleContactProfilePictureClick(e: MouseEvent<HTMLImageElement>) {
    e.stopPropagation();
    handleOpenProfilePictureModal();
  }

  function handleCheckRegistration(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    api.post(`/contacts/update-external-ids/${user?.id}`)
      .then(({ data }) => {
        handleRequestSuccess(`Checagem concluida!`);
        selectContact(data);
      })
      .catch((res) => handleRequestError(res));
  }

  const theme = useTheme();

  // function handleDeleteContact() {
  //   swal({
  //     title: 'Excluir contato',
  //     text: 'Tem certeza que deseja excluir este contato?',
  //     icon: 'warning',
  //     buttons: ['Cancelar', 'Excluir contato'],
  //     dangerMode: true,
  //   })
  //   .then(async (willDelete) => {
  //     if (!willDelete) return;

  //     try {
  //       // await api.delete(`/contacts/${user.id}`);
  //       toast.success('Contato excluido com sucesso!');
  //     } catch (err) {
  //       const { message = 'Um erro ocorreu ao excluir o contato. Por favor, tente novamente!' } = Object(err?.response?.data);
  //       toast.error(message);
  //     }
  //   });
  // }

  return (
    <>
      <Container>
        {isSelectingContact && <ContactProfileSkeleton />}
        {!isSelectingContact && (
          <>
            <img
              src={user.image_url ?? avatarImg}
              alt={user.name}
              loading="lazy"
              onClick={handleContactProfilePictureClick}
              onError={(e) => e.currentTarget.src = avatarImg}
            />
            <Infos>
              <UserName>
                <div>
                  {user.user_company_id
                    ? (
                      <a href="./" onClick={handleOpenContactInfoModal}>
                        <TextEllipsis width={window.matchMedia("(max-width: 1366px)").matches ? 100 : 200}>
                          {user.name}
                        </TextEllipsis>
                      </a>
                    )
                    : (
                      <TextEllipsis width={window.matchMedia("(max-width: 1366px)").matches ? 100 : 200}>
                        {user.name}
                      </TextEllipsis>
                    )
                  }
                </div>

                {user.user_company_id ? (
                  <Check color={theme.colors.success} size="14" />
                ) : (
                  <a href="./" onClick={handleCheckRegistration}>
                    <TextEllipsis width={window.matchMedia("(max-width: 1366px)").matches ? 100 : 200}>
                      Checar cadastro
                    </TextEllipsis>
                  </a>
                )}

                <EditIcon onClick={handleOpenEditContactModal} color={theme.colors.text} size="14" />
                {/* <TrashIcon onClick={handleDeleteContact} color={theme.colors.danger} size="14" /> */}
              </UserName>
              <UserPhone>
                <TextEllipsis width={window.matchMedia("(max-width: 1366px)").matches ? 100 : 200}>
                  {formatPhoneNumberIntl(`+${user.phone_number}`)}
                </TextEllipsis>
                {/* <InfoIcon onClick={handleOpenContactInfoModal} color={theme.colors.text} size="14" /> */}
              </UserPhone>
            </Infos>
          </>
        )}
      </Container>

      <EditContactModal
        isOpen={isEditContactModalOpen}
        onRequestClose={handleCloseEditContactModal}
        contactToEdit={user}
      />

      <ContactInfoModal
        isOpen={isContactInfoModalOpen}
        onRequestClose={handleCloseContactInfoModal}
        contact={user}
      />

      {isProfilePictureModalOpen && <ImageModal
        isOpen={isProfilePictureModalOpen}
        onRequestClose={handleCloseProfilePictureModal}
        imageUrl={user.image_url ?? avatarImg}
      />}
    </>
  );
}
