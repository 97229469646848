import { AxiosError } from "axios";
import { useContacts } from "hooks/useContacts";
import { useCount } from "hooks/utils/useCount";
import { useMutation } from "react-query";
import { api } from "services/api";
import { Contact } from "types/Contact";
import { axiosError, axiosSuccess, handleRequestError, handleRequestSuccess } from "utils/Request";

type AssumeContactProps = {
  contact: Contact;
}

async function assumeContact(contact: Contact): Promise<Contact> {
  const { data } = await api.post(`/contacts/${contact.id}/assume`).then(axiosSuccess).catch(axiosError);
  return data;
}

export function useAssumeContact() {
  const { selectContact } = useContacts();
  const { revalidateCount } = useCount();

  return useMutation(({ contact }: AssumeContactProps) => assumeContact(contact), {
    onSuccess: (data: Contact) => {
      handleRequestSuccess('Você assumiu o contato com sucesso!');
      selectContact(data);
      revalidateCount();
    },
    onError: (error: AxiosError) => {
      handleRequestError(error, 'Um erro ocorreu ao assumir o contato. Por favor, tente novamente!');
    }
  });
}
