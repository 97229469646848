import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { Macro } from "types/Macro";
import { axiosError, axiosSuccess, handleRequestError, handleRequestSuccess } from "utils/Request";

export type DeleteMacroProps = {
  macro: Macro;
}

function deleteMacro(macro: Macro) {
  return api.delete(`/macros/${macro.id}`).then(axiosSuccess).catch(axiosError);
}

export function useDeleteMacro() {
  const queryClient = useQueryClient();

  return useMutation(({ macro }: DeleteMacroProps) => deleteMacro(macro), {
    onSuccess: () => {
      handleRequestSuccess('Macro excluido com sucesso!');
      queryClient.invalidateQueries('macros');
    },
    onError: (error: AxiosError) => {
      handleRequestError(error, 'Um erro ocorreu ao excluir o macro. Por favor, tente novamente!');
    }
  });
}
