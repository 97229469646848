import { TextEllipsis } from "components/TextEllipsis";
import styled from "styled-components";

export const ProfilePicture = styled.div`
  position: relative;
  height: 40px;

  img {
    cursor: pointer;
    transition: opacity .2s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  @media only screen and (max-width: 1366px) {
    height: 30px;
  }
`;

export const MessagesCount = styled.div`
  position: absolute;
  right: -3px;
  top: -3px;
`;

export const Container = styled.tr`
  ${ProfilePicture} + div {
    margin-left: .5rem;
  }
`;

export const Info = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    gap: .3rem;

    @media only screen and (max-width: 1366px) {
      flex-direction: column;

      span.separator {
        display: none;
      }
    }

    span {
      color: ${props => props.theme.colors.text};
      line-height: 1;
    }
  }

  a {
    text-decoration: none;

    span {
      color: ${props => props.theme.colors.muted};
      transition: color .2s;
      font-weight: normal;

      &:hover {
        color: ${props => props.theme.colors.text};
      }
    }
  }
`;

export const ContactName = styled(TextEllipsis)`
  font-weight: bold;
`;

export const SearchedMessage = styled.div`
  font-weight: normal;
`;
