import { createContext, useEffect, useState } from "react";
import { getAuthorizationToken } from "../utils/Auth";
import { auth, authenticatedUser, leave } from "../services/auth";
import { AuthContextData, AuthProviderProps, SignInCredentials } from "../types/Auth";
import { User } from "types/User";

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User>({} as User);
  const isAuthenticated = Object.keys(user).length !== 0;

  useEffect(() => {
    const token = getAuthorizationToken();

    if (!token) {
      return setIsLoading(false);
    }

    async function getAuthenticatedUser() {
      try {
        const { user } = await authenticatedUser();
        setUser(user);
      } catch (error) {
        logout();
      } finally {
        setIsLoading(false);
      }
    }

    getAuthenticatedUser();
  }, []);

  async function login({ email, password, remember }: SignInCredentials) {
    try {
      const { user } = await auth({ email, password, remember });
      setIsLoading(false);
      setUser(user);
    } catch (error) {
      throw error;
    }
  }

  function logout() {
    leave();
    setUser({} as User);
  }

  return (
    <AuthContext.Provider value={{ login, logout, user, isLoading, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}
