import Modal from 'react-modal';

import { Caption, Container, ImageWrapper } from "./styles";
import { ImageModalProps } from "./types";

import closeImg from '../../assets/close.svg';
import { formatMessage } from 'utils/MessageContent';

export function ImageModal({ isOpen, onRequestClose, imageUrl, caption }: ImageModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-image-modal"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-close-image-modal"
      >
        <img
          src={closeImg}
          alt="Fechar modal"
          loading="lazy"
        />
      </button>

      <Container>
        <ImageWrapper>
          <img
            src={imageUrl}
            alt={caption ?? 'Imagem'}
            loading="lazy"
          />
        </ImageWrapper>
        {caption && (
          <Caption dangerouslySetInnerHTML={{ __html: formatMessage(caption ?? '') }} />
        )}
      </Container>
    </Modal>
  );
}
