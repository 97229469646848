import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${props => props.theme.colors.main};
  border-bottom: 5px solid ${props => props.theme.colors.primary};

  h3 {
    color: ${props => props.theme.colors.text};
    font-size: 2.25rem;
  }

  p {
    color: ${props => props.theme.colors.muted};
  }
`;
