import styled from "styled-components";

export const Form = styled.form`
  margin: 1rem 0;

  a {
    display: block;
    text-align: center;
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    margin-top: 1rem;
  }
`;
