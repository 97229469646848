import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Login } from "../pages/Login";

function AuthRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Redirect to="/login" />
      </Switch>
    </BrowserRouter>
  )
}

export default AuthRoutes;
