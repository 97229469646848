import { Container, Content } from "./styles";
import { Sidenav } from "components/Sidenav";
import { Contacts } from "components/Contacts";
import { ChatSection } from "components/ChatSection";
import { Route, Switch, Redirect } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { ContactsProvider } from "contexts/ContactsContext";

export function Home() {
  const { user } = useAuth();

  return (
    <ContactsProvider>
      <Container>
        <Content>
          <Sidenav />
          <Switch>
            <Route path="/contatos/meus-contatos" exact>
              {user && <Contacts endpoint={`/contacts?filter[user_id]=${user.id}&filter[is_archived]=false&include=category,user`} />}
            </Route>
            <Route path="/contatos/novos">
              <Contacts endpoint="/contacts?filter[new]=true&filter[is_archived]=false&include=category,user&page={page}" />
            </Route>
            <Route path="/contatos/todos-novos">
              <Contacts endpoint="/contacts?filter[new-all]=true&filter[is_archived]=false&include=category,user&page={page}" />
            </Route>
            <Route path="/contatos/arquivados">
              <Contacts endpoint="/contacts?filter[is_archived]=true&include=category,user" />
            </Route>
            <Route path="/contatos/responsavel/:id">
              <Contacts endpoint="/contacts?filter[user_id]=:id&filter[is_archived]=false&include=category,user" />
            </Route>
            <Route path="/contatos/setor/:id">
              <Contacts endpoint="/contacts?filter[category_id]=:id&filter[is_archived]=false&include=category,user" />
            </Route>
            <Route path="/contatos/procurar/:search">
              <Contacts endpoint="/contacts?filter[find]=:search&include=category,user" />
            </Route>
            <Redirect exact from="/" to="/contatos/meus-contatos" />
            <Redirect from="/contatos/*" to="/contatos/meus-contatos" />
          </Switch>
          <ChatSection />
        </Content>
      </Container>
    </ContactsProvider>
  )
}
