import useIntersectionObserver from "hooks/useIntersectionObserver";
import { useEffect, useRef } from "react";
import { useInfiniteQuery } from "react-query";
import { api } from "services/api";
import { PaginatedMessages } from "types/ChatMessage";
import { handleRequestError } from "utils/Request";

export async function fetchMessages(page: number, contactId: string, perPage: number | undefined): Promise<PaginatedMessages> {
  const { data } = await api.get(`/messages/${contactId}`, {
    params: { page, per_page: perPage },
  });
  return data;
}

export function useMessages(contactId: string, perPage: number | undefined) {
  const query = useInfiniteQuery(
    ['messages', { contactId, perPage }],
    ({ pageParam = 1 }) => fetchMessages(pageParam, contactId, perPage),
    {
      keepPreviousData: true,
      getPreviousPageParam: (res => {
        if (res.current_page - 1 >= 1) return res.current_page - 1;
        return false;
      }),
      getNextPageParam: (res => {
        if (res.current_page + 1 <= res.last_page) return res.current_page + 1;
        return false;
      }),
    }
  );

  const loadMoreRef = useRef(null);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: query.fetchNextPage,
    enabled: query.hasNextPage,
  });

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Um erro ocorreu ao listar as mensagens deste contato. Por favor, tente novamente!');
  }, [query.isError, query.error]);

  return { loadMoreRef, query };
}
