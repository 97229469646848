import { AxiosError } from "axios";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { CreateMacroData, Macro } from "types/Macro";
import { handleFormErrors } from "utils/Form";
import { axiosError, axiosSuccess, handleRequestSuccess } from "utils/Request";

type CreateMacroProps = {
  data: CreateMacroData;
}

async function createMacro(newData: CreateMacroData): Promise<Macro> {
  const { data } = await api.post('/macros', { ...newData }).then(axiosSuccess).catch(axiosError);
  return data;
}

export function useCreateMacro(errorSetter: UseFormSetError<FieldValues>) {
  const queryClient = useQueryClient();

  return useMutation(({ data }: CreateMacroProps) => createMacro(data), {
    onSuccess: (data: Macro) => {
      handleRequestSuccess(`Macro "${data.title}" criado com sucesso!`);
      queryClient.invalidateQueries('macros');
    },
    onError: (error: AxiosError) => {
      handleFormErrors(error, errorSetter);
    }
  });
}
