import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const MicIconWrapper = styled.div`
  border-radius: 50%;
  padding: .5rem;
  background: ${props => props.theme.colors.main};
  border: 1px solid ${props => props.theme.colors.borderLight};
  display: flex;
  align-items: center;
`;
