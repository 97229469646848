import { Link } from "react-scroll";
import styled, { css } from "styled-components";
import { QuotedMessageProps } from "types/QuotedMessage";

export const QuotedMessage = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['fromMe', 'sent'].includes(prop)
})<QuotedMessageProps>`
  flex: 1 0 0;
  background: ${props => props.theme.colors.quote};
  border-radius: .25rem;
  padding: 1.25rem;
  height: auto;
  position: relative;
  cursor: pointer;
  transition: filter .2s;
  overflow: hidden;

  &:hover {
    filter: brightness(0.95);
  }

  &::before {
    content: '';
    background: ${props => props.fromMe ? props.theme.colors.quoteFromMe : props.theme.colors.quoteFromContact};
    width: .5rem;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }

  ${props => props.sent && css`
    padding: 1rem;
    font-size: 0.875rem;
    border-radius: .25rem .25rem 0 0;
  `}
`;
