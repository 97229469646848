import { Container } from "./styles";

export function NoContactsSelected() {
  return (
    <Container>
      <h3>Nenhum contato selecionado</h3>
      <p>Selecione um contato na listagem ao lado para iniciar ou continuar uma conversa</p>
    </Container>
  );
}
