import { Download } from "react-feather";
import styled from "styled-components";

export const Container = styled.div`
  margin: 0.3125rem;
  padding: 0.375rem 0.4375rem 0.5rem 0.5625rem;
`;

export const DocumentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const DocumentIconWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;
  padding: .5rem;
  background: ${props => props.theme.colors.main};
  border: 1px solid ${props => props.theme.colors.borderLight};
`;

export const DownloadIcon = styled(Download)`
  &:hover {
    stroke: ${props => props.theme.colors.muted};
    cursor: pointer;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
`;
