import * as Yup from 'yup';

export function newConversationValidator() {
  return Yup.object().shape({
    name: Yup
      .string()
      .required('Por favor, informe um nome de contato válido para prosseguir.'),
    phone_number: Yup
      .string()
      .required('Por favor, informe um número de telefone válido para prosseguir.'),
    user_company_id: Yup
      .string(),
  });
}
