import { darken } from "polished";
import { css } from "styled-components";

export const InputIcon = css`
  cursor: pointer;
  position: absolute;
  top: calc(50%);
  box-sizing: content-box;
  transform: translateY(-50%);

  &:hover {
    stroke: ${props => darken(0.1, props.theme.colors.muted)};
  }
`;
