import * as Yup from 'yup';

export function loginValidator() {
  return Yup.object().shape({
    email: Yup
      .string()
      .email('Por favor, insira um email válido.')
      .required('Por favor, insira um email para prosseguir.'),
    password: Yup
      .string()
      .required('Por favor, informe sua senha.'),
    remember: Yup
      .bool(),
  });
}
