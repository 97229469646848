import { FieldValues, UseFormSetError } from "react-hook-form";
import { toast } from "react-toastify";
import { isAxiosError } from "./Request";

export function handleFormErrors(
  error: unknown,
  errorSetter: UseFormSetError<FieldValues>
) {
  let errorData = {};

  if (isAxiosError(error) && error.response && error.response.data) {
    errorData = error.response.data;
  }

  const {
    message = 'Ocorreu um erro inesperado. Por favor, tente novamente!',
    errors = [],
  } = Object(errorData);

  if (errors) {
    Object.keys(errors).forEach(function (key: string) {
      errorSetter(key, {
        type: 'manual',
        message: errors[key][0]
      });
    });
  }

  toast.error(message);
}
