import { LoadingSpinner } from "components/LoadingSpinner";
import { useLocation } from "react-router";
import { Loading } from "./styles";
import { SidenavLink } from "components/SidenavLink";
import { CategoryMenuListProps } from "./types";

export function CategoryMenuList({ categories, isLoading }: CategoryMenuListProps) {
  const { search } = useLocation();

  return (
    <>
      {isLoading && (
        <Loading>
          <LoadingSpinner size={20} thickness={2} />
        </Loading>
      )}

      {!isLoading && categories && categories.map((category) => (
        <SidenavLink
          key={category.id}
          to={{ pathname: `/contatos/setor/${category.id}`, search }}
          contactsWithNewMessages={category.contacts_with_new_messages_count}
          contactsCount={category.all_opened_contacts_count}
        >
          {category.name}
        </SidenavLink>
      ))}
    </>
  );
}
