import styled, { css } from "styled-components";
import { InputWrapperProps } from "types/InputWrapper";
import { InputIcon } from "./mixins/InputIcon";

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;

  label + & {
    margin-top: .3rem;
  }

  ${props => props.type === 'checkbox' && css`
    display: inline-block;

    & + label {
      display: inline-block;
      margin-left: .5rem;
    }
  `}

  .input-icon {
    ${InputIcon}
  }
`;
