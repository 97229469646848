import { Select } from 'components/Form/Select';
import { useAttendanceContents } from 'hooks/fetch/useAttendanceContents';
import { useFormContext } from 'react-hook-form';
import { InputGroup } from 'styles/InputGroup';

export function AttendanceContentId() {
  const { control } = useFormContext();

  const { data: attendanceContents, isLoading } = useAttendanceContents();

  return (
    <InputGroup>
      <Select
        name="attendance_content_id"
        control={control}
        label="Assunto"
        placeholder="Selecione"
        fullwidth
        config={{
          noOptionsMessage: () => 'Nenhum conteúdo de atendimento disponível.',
          options: attendanceContents ? attendanceContents.map((attendanceContent) => ({ value: attendanceContent.id, label: attendanceContent.content })) : [],
          closeMenuOnSelect: true,
          isDisabled: isLoading,
        }}
        required
      />
    </InputGroup>
  );
}
