import { DropdownListItem } from "components/Dropdown/styles";
import styled, { css } from "styled-components";
import { AudioProps, PlaybackSpeedOptionProps, PlaybackSpeedProps } from "./types";

export const Container = styled.div`
  padding: 0.3125rem 0;
  margin-left: 1rem;
`;

export const AudioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Audio = styled.audio<AudioProps>`
  &::-webkit-media-controls-play-button,
  &::-webkit-media-controls-panel,
  &::-webkit-media-controls-volume-control-container {
    background-color: ${props => props.fromMe ? props.theme.colors.messageFromMe : props.theme.colors.defaultMessage};
  }
`;

export const PlaybackSpeed = styled.span<PlaybackSpeedProps>`
  cursor: pointer;
  padding: .2rem .5rem;
  border-radius: 50%;
  background: ${props => props.theme.colors.defaultMessage};
  font-size: 0.875rem;

  ${props => props.isAudioFile && css`
    background: ${props => props.theme.colors.accent};
    color: ${props => props.theme.colors.highlightedText};
  `}
`;

export const PlaybackSpeedOption = styled(DropdownListItem)<PlaybackSpeedOptionProps>`
  ${props => props.isCurrent && css`
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
  `}
`;
