import { lighten } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.colors.contentBackground};
  color: ${props => props.theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: .5rem 0;
  }
`;

export const GoBack = styled(Link)`
  color: ${props => props.theme.colors.primary};
  transition: color .2s;
  font-weight: normal;

  &:hover {
    color: ${props => lighten(0.1, props.theme.colors.primary)};
  }
`;
