import { Select } from 'components/Form/Select';
import { useChannels } from 'hooks/fetch/useChannels';
import { useFormContext } from 'react-hook-form';
import { InputGroup } from 'styles/InputGroup';

export function ChannelId() {
  const { control } = useFormContext();

  const { data: channels, isLoading } = useChannels();

  return (
    <InputGroup>
      <Select
        name="channel_id"
        control={control}
        label="Canal"
        placeholder="Selecione"
        fullwidth
        config={{
          noOptionsMessage: () => 'Nenhum canal disponível.',
          options: channels ? channels.map((channel) => ({ value: channel.id, label: channel.title })) : [],
          closeMenuOnSelect: true,
          isDisabled: isLoading,
        }}
        required
      />
    </InputGroup>
  );
}
