import { Container, GoBack } from "./styles";

export function NotFound() {
  return (
    <Container>
      <h1>Erro 404</h1>
      <p>Ooops! Está página não foi encontrada.</p>
      <GoBack to="/">Voltar para o inicio</GoBack>
    </Container>
  )
}
