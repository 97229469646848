import { NavLink } from "react-router-dom";
import { ContactsCount, ContactsWithNewMessages, Container, MessageCount } from "./styles";
import { SidenavLinkProps } from "./types";

export function SidenavLink({
  to,
  isActive,
  children,
  contactsWithNewMessages,
  contactsCount,
  ...navLinkProps
}: SidenavLinkProps) {
  return (
    <Container>
      <NavLink
        to={to}
        isActive={(match, location) => {
          if (!match) {
            return false;
          }

          if (contactsWithNewMessages) {
            document.title = `(${contactsWithNewMessages?.toString() ?? ''}) WhatsCodex`;
          } else {
            document.title = 'WhatsCodex';
          }

          return true;
        }}
        {...navLinkProps}
      >
        {children}
      </NavLink>

      <MessageCount>
        {
          typeof contactsWithNewMessages !== 'undefined'
          && contactsWithNewMessages >= 0
          && (
            <ContactsWithNewMessages data-tip="Contatos com novas mensagens.">
              {contactsWithNewMessages}
            </ContactsWithNewMessages>
          )
        }
        {
          typeof contactsCount !== 'undefined'
          && contactsCount >= 0
          && (
            <ContactsCount data-tip="Contatos ativos (que não estão arquivados).">
              {contactsCount}
            </ContactsCount>
          )
        }
      </MessageCount>
    </Container>
  );
}
