import styled from "styled-components";

export const Info = styled.div`
  padding: 1.25rem .3rem;
  border-top: 1px solid ${props => props.theme.colors.borderLight};
  border-bottom: 1px solid ${props => props.theme.colors.borderLight};

  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    background: ${props => props.theme.colors.hover};
    cursor: pointer;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: .1rem;

    span:not(:first-child) {
      color: ${props => props.theme.colors.muted};
    }
  }
`;
