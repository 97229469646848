import styled, { css } from "styled-components";
import { DropdownListItemProps, DropdownMenuProps } from "./types";

export const Container = styled.div`
  position: relative;
`;

export const DropdownMenu = styled.div<DropdownMenuProps>`
  position: absolute;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);
  margin-top: .3rem;
  z-index: 999;

  ${props => props.pin === 'right' ? css`
    right: 0;
  ` : css`
    left: 0;
  `}

  ${props => props.mode === 'up' && css`
    bottom: 0;
  `}

  ${props => props.mode === 'down' && css`
    top: 0;
  `}

  ul {
    min-width: 100%;
    display: inline-block;
    list-style: none;
    background: ${props => props.theme.colors.dropdownMenu};
    border: 1px solid ${props => props.theme.colors.border};
    padding: .5rem 0;
    border-radius: .25rem;
    max-height: 500px;
    overflow-y: scroll;
  }
`;

export const DropdownListItem = styled.li<DropdownListItemProps>`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: .5rem 1.25rem;
  cursor: pointer;
  font-weight: 400;
  color: ${props => props.theme.colors.text};

  span {
    width: 100%;
    max-width: 20rem;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background: ${props => props.theme.colors.main};
    color: ${props => props.theme.colors.hoveredText};
  }

  ${props => props.primary && css`
    color: ${props => props.theme.colors.primary};

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  `}

  ${props => props.danger && css`
    color: ${props => props.theme.colors.danger};

    &:hover {
      color: ${props => props.theme.colors.danger};
    }
  `}
`;
