import { useController } from "react-hook-form";
import { InputProps } from "types/Input";
import { InputWrapper } from 'styles/InputWrapper';
import { FormControl } from 'styles/FormControl';

import 'react-phone-number-input/style.css';

export function Input({
  type = "text",
  name,
  label,
  id,
  icons,
  control,
  ...props
}: InputProps) {
  const { field, fieldState } = useController({ name, control });

  return (
    <>
      {label && id && <label htmlFor={id}>{label}</label>}

      <InputWrapper type={type}>
        {icons}
        <FormControl
          type={type}
          id={id}
          as="input"
          hasError={!!fieldState.error}
          {...field} {...props}
        />
      </InputWrapper>

      {fieldState.error && <span className="input-error-message">{fieldState.error.message}</span>}
    </>
  );
};
