import styled from "styled-components";

export const Count = styled.div`
  display: inline-block;
  padding: .35em .65em;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  font-family: Arial;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.highlightedText};
`;
