import styled, { css } from "styled-components";
import { VideoProps } from "./types";

export const Container = styled.div`
  border-radius: 0.625rem;
  overflow: hidden;
`;

export const Video = styled.video<VideoProps>`
  display: 'block';
  width: 100%;
  max-height: 15rem;

  ${props => !props.hasCaption && css`
    height: 100%;
  `}
`;

export const Caption = styled.p`
  margin: 0.3125rem;
  padding: 0 0.4375rem .5rem 0.5625rem;
  line-height: 1.3;
  white-space: pre-wrap;
  word-break: break-word;
`;
