import { rgba } from "polished";

const dark = {
  title: 'dark',
  colors: {
    primary: '#4294ff',
    accent: '#ffa22b',

    danger: '#ff5c54',
    success: '#54ff68',

    annotationBorder: '#ffa22b',
    annotation: '#ffa22b',
    automaticAnnotation: '#fafafa',
    automaticAnnotationText: '#363f5f',

    text: rgba(255, 255, 255, 0.70),
    muted: rgba(255, 255, 255, 0.5),

    hover: '#4e5459',
    hoveredText: '#fff',

    main: '#202020',

    selected: '#64696e',

    border: '#202020',
    borderLight: '#3e454a',

    background: '#121212',

    inputBackground: '#202020',

    defaultMessage: '#4e5459',
    messageFromMe: '#4294ff',

    messageOverlays: rgba(255, 255, 255, 0.8),
    messageText: '#fff',
    deletedMessage: rgba(255, 255, 255, 0.8),

    highlightedText: '#fff',
    dropdownMenu: '#262626',
    modalCaption: '#fff',
    contentBackground: '#2f3336',
    buttonText: '#fff',

    spinner: rgba(255, 255, 255, 0.1),

    switchHandler: '#ced2de',

    quote: '#121212',
    quoteFromMe: '#4294ff',
    quoteFromContact: '#ced2de',
  },
  styles: {
    smallShadow: 'none',
  },
}

export default dark;
