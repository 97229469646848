import styled from "styled-components";

export const ImageWrapper = styled.div`
  max-height: 37.5rem;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Caption = styled.p`
  text-align: center;
  font-size: 1rem;
  line-height: 1.3;
  color: ${props => props.theme.colors.modalCaption};
  padding: .5rem 1.25rem;
  margin-top: 0;
  max-height: 5rem;
  overflow-y: scroll;
`;
