import * as Yup from 'yup';

export function archiveContactValidator() {
  return Yup.object().shape({
    archive_without_register: Yup.boolean(),
    remove_responsible: Yup.boolean(),
    remove_category: Yup.boolean(),
    attendance_content_id: Yup.string().when('archive_without_register', {
      is: false,
      then: Yup.string().required('Informe o assunto.'),
    }),
    channel_id: Yup.string().when('archive_without_register', {
      is: false,
      then: Yup.string().required('Informe o canal.'),
    }),
    category_id: Yup.string().when('archive_without_register', {
      is: false,
      then: Yup.string().required('Informe o setor.'),
    }),
    annotation: Yup.string(),
  });
}
