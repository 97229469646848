import { ArrowRightCircle } from "react-feather";
import { useTheme } from "styled-components";
import { MessageContainer } from "styles/MessageContainer";
import { MessageTitle } from "styles/MessageTitle";
import { formatMessage } from "utils/MessageContent";
import { TextMessageProps } from "./types";

export function TextMessage({ message }: TextMessageProps) {
  const theme = useTheme();

  return (
    <MessageContainer>
      {message.is_forwarded && (
        <MessageTitle spaced>
          <ArrowRightCircle size="18" color={theme.colors.text} />
          Encaminhada
        </MessageTitle>
      )}
      <span dangerouslySetInnerHTML={{ __html: formatMessage(message.body) }} />
    </MessageContainer>
  );
}
