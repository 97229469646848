import styled from "styled-components";

export const Container = styled.span`
  white-space: pre-wrap;
  word-break: break-word;
  display: flex;
  align-items: center;
  gap: .5rem;
  color: ${props => props.theme.colors.accent};
`;
