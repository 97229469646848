import { useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "services/api";
import { Category } from "types/Category";
import { handleRequestError } from "utils/Request";

export async function fetchCategories(): Promise<Category[]> {
  const { data } = await api.get('/categories');
  return data;
}

export function useCategories() {
  const query = useQuery('categories', fetchCategories);

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Ocorreu um erro ao listar os setores. Por favor, tente novamente.');
  }, [query.isError, query.error]);

  return query;
}
