import { useEffect } from "react";
import Modal from 'react-modal';
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";

import { ModalTitle } from "styles/Modal";
import { Form } from "styles/Form";
import { InputGroup } from "styles/InputGroup";
import { Input } from "components/Form/Input";
import { Button } from "styles/Button";

import { EditCategoryModalProps } from "./types";

import { categoryValidator } from "./validation";

import closeImg from '../../assets/close.svg';
import { useEditCategory } from "hooks/mutations/useEditCategory";
import { EditCategoryData } from "types/Category";

const validationSchema = categoryValidator();

export function EditCategoryModal({ isOpen, onRequestClose, categoryToEdit }: EditCategoryModalProps) {
  const { handleSubmit, setValue, setError, control, reset, formState: { isSubmitting } } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    setValue('name', categoryToEdit.name);
  }, [setValue, categoryToEdit]);

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  const editCategory = useEditCategory(setError);

  async function handleEditCategory({ name }: EditCategoryData) {
    await editCategory.mutateAsync({ categoryToEdit, data: { name } });
    handleRequestClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={handleRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <div>
        <ModalTitle>Editar setor</ModalTitle>

        <Form onSubmit={handleSubmit(handleEditCategory)}>
          <InputGroup>
            <Input
              control={control}
              type="text"
              name="name"
              id="name"
              label="Nome"
              placeholder="Nome do setor"
              maxLength={255}
              fullwidth
            />
          </InputGroup>
          <InputGroup>
            <Button type="submit" fullwidth disabled={isSubmitting}>
              {isSubmitting ? 'Carregando...' : 'Editar setor'}
            </Button>
          </InputGroup>
        </Form>
      </div>
    </Modal>
  );
}
