import { darken } from "polished";
import styled, { css } from "styled-components";
import { MessageTitle } from "styles/MessageTitle";

type ContainerProps = {
  isAutomatic: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-top: 3px solid ${props => props.theme.colors.annotationBorder};
  background: ${props => props.theme.colors.annotation};
  border-radius: .25rem;
  
  ${props => props.isAutomatic && css`
    background: ${props => props.theme.colors.automaticAnnotation};
    border-top: 3px solid ${props => darken(0.1, props.theme.colors.annotationBorder)};

    ${Content} {
      color: ${props => props.theme.colors.automaticAnnotationText};
    }

    ${MessageTitle} {
      color: ${props => props.theme.colors.automaticAnnotationText};
    }
  `}
`;

export const Content = styled.div`
  margin: 0.3125rem 2.5rem 0.3125rem 0.3125rem;
  padding: 0.375rem 0.4375rem 0.5rem 0.5625rem;
`;

export const Text = styled.p`
  line-height: 1.3;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const AttachmentLink = styled.a`
  display: inline-block;
  margin-top: .3rem;

  &, &:visited {
    color: ${props => props.theme.colors.primary};
  }
`;
