import { Mic, Send } from "react-feather";
import { useTheme } from "styled-components";
import { Button } from "styles/Button";
import { SendMessageButtonProps } from "./types";

export function SendMessageButton({ formState, isRecordingAudio, onRecordAudioMessage, isAnnotation }: SendMessageButtonProps) {
  const theme = useTheme();

  if (formState.dirtyFields.message || formState.dirtyFields.caption) {
    return (
      <Button type="submit" disabled={formState.isSubmitting}>
        <Send color={theme.colors.buttonText} size="18" />
      </Button>
    );
  } else if (onRecordAudioMessage && !isRecordingAudio) {
    return (
      <Button type="button" onClick={onRecordAudioMessage} disabled={isAnnotation}>
        <Mic color={theme.colors.buttonText} size="18" />
      </Button>
    );
  } else if (!isRecordingAudio) {
    return (
      <Button type="submit" disabled={formState.isSubmitting}>
        <Send color={theme.colors.buttonText} size="18" />
      </Button>
    );
  } else {
    return null;
  }
}
