import { useFormContext } from "react-hook-form";
import { InputGroup } from "styles/InputGroup";
import { CustomTextarea } from "./styles";


export function WhatsAppAnnotation() {
  const { control } = useFormContext();

  return (
    <InputGroup>
      <CustomTextarea
        name="whatsapp_annotation"
        control={control}
        label="Anotação para o destinatário"
        placeholder="Digite a anotação"
        fullwidth
      />
    </InputGroup>
  );
}
