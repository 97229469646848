import { TextEllipsisProps } from "./types";
import { useEffect, useRef, useState } from "react";
import { EllipsedText } from "./styles";

export function TextEllipsis({ children, width, ...props }: TextEllipsisProps) {
  const element = useRef<HTMLElement>(null);
  const [hasTooltip, setHasTooltip] = useState(false);

  useEffect(() => {
    if (!element.current) return;
    const { offsetWidth, scrollWidth } = element.current;

    if (width === offsetWidth || scrollWidth > offsetWidth) {
      setHasTooltip(true);
    }
  }, [element, setHasTooltip, width]);

  return (
    <EllipsedText
      ref={element}
      data-tip={hasTooltip ? children : ''}
      maxWidth={width}
      {...props}
    >
      {children}
    </EllipsedText>
  )
}
