import { useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "services/api";
import { Count } from "types/Count";
import { handleRequestError } from "utils/Request";

export async function fetchCount(): Promise<Count> {
  const { data } = await api.get('/count/all');
  return data;
}

export function useCountContacts() {
  const query = useQuery('count', fetchCount);

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Ocorreu um erro ao listar a contagem de contatos. Por favor, tente novamente.');
  }, [query.isError, query.error]);

  return query;
}
