import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Video = styled.video`
  width: 40px;
`;

export const Caption = styled.p`
  white-space: pre-wrap;
  word-break: break-word;
`;
