import { User } from "react-feather";
import { useTheme } from "styled-components";
import { ContactIconWrapper, Container } from "./styles";
import { ContactQuoteProps } from "./types";

export function ContactQuote({ quote }: ContactQuoteProps) {
  const theme = useTheme();

  return (
    <Container>
      <ContactIconWrapper>
        <User color={theme.colors.muted} size="16" />
      </ContactIconWrapper>
      <p>Contato(s)</p>
    </Container>
  );
}
