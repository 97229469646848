import { Fragment, useEffect } from "react";
import { isBefore, subDays } from "date-fns";

import { Table } from "styles/Table";

import { useContacts as useFetchContacts } from "hooks/fetch/useContacts";

import ReactTooltip from "react-tooltip";
import { Contact } from "components/Contact";
import { ContactsTableProps, ShowTransferredContactAlertData } from "./types";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Container, Loading, SmallLoading } from "./styles";
import { useSocket } from "hooks/useSocket";
import { isEmpty } from "utils/Misc";
import { playNotificationSound, playTransferredContactSound } from "utils/Audio";
import { api } from "services/api";
import { useContacts } from "hooks/useContacts";
import { handleRequestError } from "utils/Request";
import { useCount } from "hooks/utils/useCount";
import Swal from "sweetalert2";

export function ContactsTable({ endpoint }: ContactsTableProps) {
  const { loadMoreRef, query: { data, isLoading, isFetchingNextPage, refetch } } = useFetchContacts(endpoint);

  const { selectContact } = useContacts();

  const { socket } = useSocket();

  const { revalidateCount } = useCount();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  useEffect(() => {
    if (!socket || isEmpty(socket)) return;

    socket.on('new_user_contact_message', () => {
      playNotificationSound();
    });

    return () => {
      socket.off('new_user_contact_message');
    }
  }, [socket, refetch]);

  useEffect(() => {
    if (!socket || isEmpty(socket)) return;

    socket.on('new_contact_message', () => {
      playNotificationSound();
      revalidateCount();
    });

    return () => {
      socket.off('new_contact_message');
    }
  }, [socket, revalidateCount]);

  useEffect(() => {
    if (!socket || isEmpty(socket)) return;

    async function selectTransferredContact(id: string) {
      try {
        const { data } = await api.get(`/contacts/${id}`);
        selectContact(data);
      } catch (error) {
        handleRequestError(error, 'Erro ao selecionar contato. Por favor, tente novamente!');
      }
    }

    async function showTransferredContactAlert({ contactId }: ShowTransferredContactAlertData) {
      playTransferredContactSound();
      revalidateCount();

      const willSelect = await Swal.fire({
        title: 'Novo contato transferido',
        text: 'Um contato foi transferido para você. Deseja selecionar este contato e dar início a conversa?',
        icon: 'info',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Ignorar',
        confirmButtonText: 'Selecionar contato',
        allowEnterKey: false,
      });

      if (!willSelect.isConfirmed) return false;

      selectTransferredContact(contactId);
    }

    socket.on('transferred_contact', showTransferredContactAlert);

    return () => {
      socket.off('transferred_contact', showTransferredContactAlert);
    }
  }, [socket, selectContact, revalidateCount]);

  return (
    <Container>
      {isLoading && !isFetchingNextPage && (
        <Loading>
          <LoadingSpinner size={25} thickness={2} />
        </Loading>
      )}

      {!isLoading && (
        <Table>
          <thead>
            <tr>
              <th>Contato</th>
              <th>Última interação</th>
              <th>Responsável</th>
              <th>Setor</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data && data.pages && data.pages.map((page, index) => (
              <Fragment key={index}>
                {page.data && page.data.map((contact) => {
                  const isInactive = isBefore(new Date(contact.last_seen ?? ''), subDays(new Date(), 3));

                  return (
                    <Contact
                      key={contact.id}
                      isInactive={isInactive}
                      contact={contact}
                    />
                  );
                })}
              </Fragment>
            ))}
          </tbody>
        </Table>
      )}

      <div ref={loadMoreRef} style={{ visibility: 'hidden' }}></div>

      {isFetchingNextPage && (
        <SmallLoading>
          <LoadingSpinner size={25} thickness={2} />
        </SmallLoading>
      )}
    </Container>
  );
}
