// import { useContacts } from "hooks/useContacts";
// import { useMessages } from "hooks/useMessages";
// import { useState } from "react";
// import { toast } from "react-toastify";
// import { api } from "services/api";
// import { handleRequestError } from "utils/Request";

export function SyncMessages() {
  // const [isSyncing, setIsSyncing] = useState(false);

  // const { selectedContact } = useContacts();
  // const {
  //   messagesQuery: { query },
  //   setIsRefetchingMessages,
  // } = useMessages();

  // async function handleSyncMessages() {
  //   setIsSyncing(true);

  //   try {
  //     const response = await api.post(`/messages/sync/${selectedContact.id}`);
  //     toast.success(response.data.message);

  //     setIsRefetchingMessages(true);
  //     await query.refetch();
  //     setIsRefetchingMessages(false);
  //   } catch (error) {
  //     handleRequestError(error, 'Erro ao sincronizar mensagens. Por favor, tente novamente!');
  //   }

  //   setIsSyncing(false);
  // }

  return null;
  // return (
  //   <SyncMessagesButton
  //     onClick={handleSyncMessages}
  //     disabled={isSyncing}
  //   >
  //     {isSyncing ? 'Sincronizando...' : 'Sincronizar mensagens'}
  //   </SyncMessagesButton>
  // );
}
