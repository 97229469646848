import { Paperclip } from "react-feather";
import styled, { keyframes } from "styled-components";
import { FormRow } from "styles/FormRow";

export const Bottom = styled.div`
  width: 100%;
  padding: 0 1.25rem;
  bottom: 0;
  border-top: 1px solid ${props => props.theme.colors.border};
`;

export const AttachmentIcon = styled(Paperclip)`
  right: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
`;

const blinkRecordingIcon = keyframes`
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
`;

export const RecordingTime = styled.span`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text};

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;
    background: ${props => props.theme.colors.danger};
    animation-name: ${blinkRecordingIcon};
    animation-duration: .6s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }
`;

export const MessageFormOptionsRow = styled(FormRow)`
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1rem;
`;
