import useIntersectionObserver from "hooks/useIntersectionObserver";
import { useEffect, useRef } from "react";
import { useInfiniteQuery } from "react-query";
import { api } from "services/api";
import { PaginatedContacts } from "types/Contact";
import { handleRequestError } from "utils/Request";

export async function fetchContacts(endpoint: string, page: number): Promise<PaginatedContacts> {
  const { data } = await api.get(endpoint, {
    params: { page },
  });
  return data;
}

export function useContactsToForward(endpoint: string = '/contacts') {
  const query = useInfiniteQuery(
    ['contactsToForward', endpoint],
    ({ pageParam = 1 }) => fetchContacts(endpoint, pageParam),
    {
      keepPreviousData: true,
      getPreviousPageParam: (res => {
        if (res.current_page - 1 >= 1) return res.current_page - 1;
        return false;
      }),
      getNextPageParam: (res => {
        if (res.current_page + 1 <= res.last_page) return res.current_page + 1;
        return false;
      }),
    }
  );

  const loadMoreRef = useRef(null);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: query.fetchNextPage,
    enabled: query.hasNextPage,
  });

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Um erro ocorreu ao listar os contatos. Por favor, tente novamente!');
  }, [query.isError, query.error]);

  return { loadMoreRef, query };
}
