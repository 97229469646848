import { useEffect, useRef, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

export function useAudio(availableSpeeds = [1, 1.5, 2, 3]) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [currentSpeed, setCurrentSpeed] = useState<number | null>(null);

  const [defaultAudioSpeed] = useLocalStorage<number>(
    '@WhatsCodex:defaultAudioSpeed',
    1
  );

  useEffect(() => {
    if (!defaultAudioSpeed) return;
    setCurrentSpeed(Number(defaultAudioSpeed));
    if (!audioRef.current) return;
    audioRef.current.playbackRate = Number(defaultAudioSpeed);
    // eslint-disable-next-line
  }, [defaultAudioSpeed, audioRef.current]);

  function changeSpeed() {
    if (!currentSpeed) return;

    const lastSpeed = availableSpeeds[availableSpeeds.length - 1];

    const nextSpeed = (lastSpeed === currentSpeed)
      ? availableSpeeds[0]
      : availableSpeeds[availableSpeeds.indexOf(currentSpeed) + 1];

    setCurrentSpeed(nextSpeed);

    if (!audioRef.current) return;
    audioRef.current.playbackRate = nextSpeed;
  }

  return { audioRef, currentSpeed, changeSpeed, availableSpeeds };
}
