import { Mic } from "react-feather";
import { useTheme } from "styled-components";
import { Container, MicIconWrapper } from "./styles";
import { AudioQuoteProps } from "./types";

export function AudioQuote({ isAudioFile }: AudioQuoteProps) {
  const theme = useTheme();

  return (
    <Container>
      <MicIconWrapper>
        <Mic color={isAudioFile ? theme.colors.accent : theme.colors.muted} size="16" />
      </MicIconWrapper>
      <p>Mensagem de áudio</p>
    </Container>
  );
}
