import { MouseEvent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { ContactInfoModalProps } from "./types";

import closeImg from '../../assets/close.svg';
import { ModalTitle } from 'styles/Modal';
import { Home, Mail, User } from 'react-feather';
import { useTheme } from 'styled-components';
import { Info } from './styles';
import { toast } from 'react-toastify';
import { api } from 'services/api';
import { AdditionalData } from 'types/AdditionalInfo';
import { handleRequestError } from 'utils/Request';
import { isEmpty } from 'utils/Misc';

export function ContactInfoModal({
  isOpen,
  onRequestClose,
  contact,
}: ContactInfoModalProps) {
  const theme = useTheme();
  const [additionalData, setAdditionalData] = useState<AdditionalData>({} as AdditionalData);

  function handleCopyToClipboard(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    navigator.clipboard.writeText(e.currentTarget.dataset.info ?? '');
    toast.info('Informação copiada com sucesso.');
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (isEmpty(contact) || !isOpen) return;

    async function fetchContactAdditionalInfo() {
      try {
        const response = await api.get(`/contacts/additional-data/${contact.id}`);
        setAdditionalData(response.data.data);
      } catch (error) {
        handleRequestError(error, 'Erro ao buscar informações adicionais deste contato. Por favor, tente novamente!');
        onRequestClose();
      }
    }

    fetchContactAdditionalInfo();
  }, [contact, isOpen, onRequestClose]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <button
          type="button"
          onClick={onRequestClose}
          className="react-modal-close"
        >
          <img src={closeImg} alt="Fechar modal" loading="lazy" />
        </button>

        <div>
          <ModalTitle>Informações do contato "{contact.name}"</ModalTitle>

          {additionalData ? (
            <>
              <Info
                onClick={handleCopyToClipboard}
                data-info={additionalData.name}
                title="Clique para copiar"
              >
                <User size="24" color={theme.colors.text}></User>
                <div>
                  <span>{additionalData.name}</span>
                  <span>Nome do contato</span>
                </div>
              </Info>

              <Info
                onClick={handleCopyToClipboard}
                data-info={additionalData.email}
                title="Clique para copiar"
              >
                <Mail size="24" color={theme.colors.text}></Mail>
                <div>
                  <span>{additionalData.email}</span>
                  <span>Email</span>
                </div>
              </Info>

              {additionalData.real_estate && (
                <Info
                  onClick={handleCopyToClipboard}
                  data-info={additionalData.real_estate?.name}
                  title="Clique para copiar"
                >
                  <Home size="24" color={theme.colors.text}></Home>
                  <div>
                    <a
                      href={additionalData.redirect_url}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {additionalData.real_estate?.name}
                    </a>
                    <span>Imobiliária</span>
                  </div>
                </Info>
              )}
            </>
          ) : <p>Nenhuma informação adicional encontrada.</p>}
        </div>
      </Modal>
    </>
  );
}
