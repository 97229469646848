import io from 'socket.io-client';
import { createContext, useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import { SocketContextData, SocketProviderProps } from "types/Socket";

export const SocketContext = createContext({} as SocketContextData);

export function SocketProvider({ children, user }: SocketProviderProps) {
  const [socket, setSocket] = useState({} as Socket);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const socketIoClient = io(`${process.env.REACT_APP_SOCKET_URL}`, {
      auth: {
        token: process.env.REACT_APP_SOCKET_TOKEN,
      },
      query: {
        userId: user.id,
      },
      reconnectionDelay: 20000,
      reconnectionDelayMax: 20000,
    });

    setSocket(socketIoClient);

    socketIoClient.on('connect', () => {
      console.log('Socket: connection established successfully!');
    });

    socketIoClient.on('connect_error', (error) => {
      console.error(`Socket: connection error due to ${error.message}`);
      setError(error);
    });

    socketIoClient.on("reconnect", (attempt) => {
      console.warn(`Socket: reconnect. Attempt: ${JSON.stringify(attempt)}`);
    });

    socketIoClient.on("reconnect_attempt", (attempt) => {
      console.warn(`Socket: reconnect_attempt. Attempt: ${JSON.stringify(attempt)}`);
    });

    socketIoClient.on("reconnect_error", (error) => {
      console.warn(`Socket: reconnect_error. Error: ${JSON.stringify(error)}`);
    });

    socketIoClient.on("reconnect_failed", () => {
      console.warn(`Socket: reconnect_failed.`);
    });

    return () => {
      socketIoClient.close();
      socketIoClient.off('connect');
      socketIoClient.off('connect_error');
      socketIoClient.off('reconnect');
      socketIoClient.off('reconnect_attempt');
      socketIoClient.off('reconnect_error');
      socketIoClient.off('reconnect_failed');
    }
  }, [user]);

  return (
    <SocketContext.Provider value={{ socket, error }}>
      {children}
    </SocketContext.Provider>
  )
}
