import { SocketProvider } from "contexts/SocketContext";
import { Loading } from "../components/Loading";
import { useAuth } from "../hooks/useAuth";
import AppRoutes from "./app.routes";
import AuthRoutes from "./auth.routes";

function Routes() {
  const { isAuthenticated, isLoading, user } = useAuth();

  if (isLoading) return <Loading />;

  return isAuthenticated && user
    ? (
      <SocketProvider user={user}>
        <AppRoutes />
      </SocketProvider>
    )
    : <AuthRoutes />
}

export default Routes;
