import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.colors.primary};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.section`
  width: 100%;
  max-width: 576px;
  background: ${props => props.theme.colors.contentBackground};
  border-radius: .25rem;
  padding: 3rem;

  h1 {
    color: ${props => props.theme.colors.text};
    text-align: center;
  }
`;
