import { formatMessage } from "utils/MessageContent";
import { Caption, Container } from "./styles";
import { ImageQuoteProps } from "./types";

export function ImageQuote({ quote }: ImageQuoteProps) {
  return (
    <Container>
      <img width="40" src={quote.body} alt={quote.caption ?? ''} loading="lazy" />
      <Caption dangerouslySetInnerHTML={{ __html: formatMessage(quote.caption ?? 'Imagem') }} />
    </Container>
  );
}
