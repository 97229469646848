import ForwardedInput from './ForwardedInput';

import { useController } from "react-hook-form";
import { PhoneInputWithCountry } from "./styles";
import { InputWrapper } from 'styles/InputWrapper';

import 'react-phone-number-input/style.css';
import { PhoneInputProps } from './types';

export function PhoneInput({
  name,
  label,
  id,
  icons,
  control,
  options,
  ...props
}: PhoneInputProps) {
  const { field, fieldState } = useController({ name, control });

  return (
    <>
      {label && id && <label htmlFor={id}>{label}</label>}

      <InputWrapper type="tel">
        {icons}
        <PhoneInputWithCountry
          type="tel"
          id={id}
          control={control}
          hasError={!!fieldState.error}
          inputComponent={ForwardedInput}
          {...field} {...props} {...options}
        />
      </InputWrapper>

      {fieldState.error && <span className="input-error-message">{fieldState.error.message}</span>}
    </>
  );
};
