import { useQueryClient } from "react-query";

export function useCount() {
  const queryClient = useQueryClient();

  function revalidateCount() {
    queryClient.invalidateQueries('contacts');
    queryClient.invalidateQueries('count');
  }

  return { revalidateCount };
}
