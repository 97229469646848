import { Edit3 } from "react-feather";
import { useTheme } from "styled-components";
import { MessageTitle } from "styles/MessageTitle";
import { formatMessage } from "utils/MessageContent";
import { AttachmentLink, Container, Content, Text } from "./styles";
import { AnnotationProps } from "./types";

export function Annotation({ annotation }: AnnotationProps) {
  const theme = useTheme();

  const annotationContent = annotation.type === 'chat' ? annotation.body : annotation.caption;

  const isAutomatic = !annotation.user_id;

  return (
    <Container isAutomatic={isAutomatic}>
      <Content>
        <MessageTitle spaced>
          <Edit3 size="18" color={isAutomatic ? theme.colors.automaticAnnotationText : theme.colors.text} />
          Anotação {!annotation.user_id ? 'automática' : ''}
        </MessageTitle>
        <Text dangerouslySetInnerHTML={{ __html: formatMessage(annotationContent ?? '') }} />
        {annotation.type !== 'chat' && (
          <AttachmentLink href={annotation.body} target="_blank" rel="noopener">Clique para ver anexo</AttachmentLink>
        )}
      </Content>
    </Container>
  );
}
