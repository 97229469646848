import { AxiosError } from "axios";
import { useContacts } from "hooks/useContacts";
import { useCount } from "hooks/utils/useCount";
import { useMutation } from "react-query";
import { api } from "services/api";
import { Category } from "types/Category";
import { Contact } from "types/Contact";
import { axiosError, axiosSuccess, handleRequestError, handleRequestSuccess } from "utils/Request";

type ChangeContactCategoryProps = {
  contact: Contact;
  category: Category;
}

async function changeContactCategory(contact: Contact, category: Category): Promise<Contact> {
  const { data } = await api.post(`/contacts/${contact.id}/category/${category.id}`)
    .then(axiosSuccess)
    .catch(axiosError);
  return data;
}

export function useChangeContactCategory() {
  const { selectContact } = useContacts();
  const { revalidateCount } = useCount();

  return useMutation(({ contact, category }: ChangeContactCategoryProps) => changeContactCategory(contact, category), {
    onSuccess: (data: Contact) => {
      handleRequestSuccess(`Setor do contato editado com sucesso!`);
      selectContact(data);
      revalidateCount();
    },
    onError: (error: AxiosError) => {
      handleRequestError(error, 'Um erro ocorreu ao editar o setor do contato. Por favor, tente novamente!');
    }
  });
}
