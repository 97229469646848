import { deleteAuthorizationToken, setAuthorizationToken } from '../utils/Auth';
import { SignInCredentials } from '../types/Auth';
import { api } from './api';

export async function auth(data: SignInCredentials) {
  try {
    const response = await api.post('/auth/login', data);
    setAuthorizationToken(response.data.token);
    return response.data;
  } catch(error) {
    throw error;
  }
}

export async function leave() {
  try {
    deleteAuthorizationToken();
    await api.post('/auth/logout');
  } catch (error) {
    throw error;
  }
}

export async function authenticatedUser() {
  try {
    const response = await api.get('/me');
    return response.data;
  } catch (error) {
    throw error;
  }
}
