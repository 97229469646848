import styled from "styled-components";

export const Contact = styled.tr`
  img + div {
    margin-left: .5rem;

    span:first-child {
      font-weight: bold;
    }
  }
`;

export const ContactsWrapper = styled.div`
  max-height: 37.5rem;
  overflow-y: scroll;
  margin-bottom: 1rem;
`;

export const SmallLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

export const LoadingIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2.5rem;
`;
