import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.colors.contentBackground};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    display: block;
    font-size: 1.5rem;
    margin-top: 1rem;
    color: ${props => props.theme.colors.text};
  }
`;
