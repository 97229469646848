import { useFormContext } from 'react-hook-form';
import { CustomTextarea } from './styles';
import { InputGroup } from 'styles/InputGroup';

export function Annotation() {
  const { control } = useFormContext();

  return (
    <InputGroup>
      <CustomTextarea
        name="annotation"
        control={control}
        label="Anotação para a central"
        placeholder="Digite a anotação"
        fullwidth
        autoFocus
      />
    </InputGroup>
  );
}
