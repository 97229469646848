import styled from "styled-components";
import { Edit2, Info, Trash, X } from "react-feather";
import { darken } from "polished";

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text};

  img {
    border-radius: 50%;
    margin-right: .5rem;
    width: 40px;
    cursor: pointer;
    transition: opacity .2s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  span {
    display: block;
    line-height: 1.3;

    &:first-child {
      font-weight: bold;
    }
  }
`;

export const Infos = styled.div``;

export const EditIcon = styled(Edit2)`
  cursor: pointer;

  &:hover {
    stroke: ${props => props.theme.colors.muted};
  }
`;

export const TrashIcon = styled(Trash)`
  cursor: pointer;

  &:hover {
    stroke: ${props => darken(0.1, props.theme.colors.danger)};
  }
`;

export const UserName = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
`;

export const UserPhone = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
`;

export const InfoIcon = styled(Info)`
  cursor: pointer;

  &:hover {
    stroke: ${props => props.theme.colors.muted};
  }
`;

export const XIcon = styled(X)`
  right: .5rem;
`;
