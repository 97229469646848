import { useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "services/api";
import { Macro } from "types/Macro";
import { handleRequestError } from "utils/Request";

type MacrosParams = {
  sort?: string;
}

export async function fetchMacros(params?: MacrosParams): Promise<Macro[]> {
  const { data } = await api.get('/macros', { params });
  return data;
}

export function useMacros(params?: MacrosParams) {
  const query = useQuery('macros', () => fetchMacros(params));

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Ocorreu um erro ao listar os macros. Por favor, tente novamente!');
  }, [query.isError, query.error]);

  return query;
}
