import { useEffect, useRef, useState } from "react";
import { Send, Trash } from "react-feather";
import { useTheme } from "styled-components";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import OpusRecorder from 'opus-media-recorder';

import { ContentEditable } from "components/Form/ContentEditable";
import { SendMessageButton } from "components/SendMessageButton";

import { useContacts } from "hooks/useContacts";
import { useTimer } from "hooks/useTimer";

import { Button } from "styles/Button";
import { Form } from "styles/Form";
import { FormRow } from "styles/FormRow";
import { InputGroup } from "styles/InputGroup";
import { ChatMessage } from "types/ChatMessage";
import { AttachmentIcon, Bottom, Buttons, Footer, MessageFormOptionsRow, RecordingTime } from "./styles";

import { MessageData, MessageFormProps, SendAudioMessageProps, SendTextMessageProps } from "./types";

import { isEmpty } from "utils/Misc";
import { handleRequestError } from "utils/Request";
import { formatTime } from "utils/Timer";

import { Checkbox } from "components/Form/Checkbox";
import { SyncMessages } from "components/SyncMessages";
import { useMessages } from "hooks/useMessages";
import { useCount } from "hooks/utils/useCount";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import { api } from "services/api";
import { Contact } from "types/Contact";

const workerOptions = {
  encoderWorkerFactory: function () {
    return new Worker(process.env.REACT_APP_URL + '/opus-media-recorder/encoderWorker.umd.js')
  },
  OggOpusEncoderWasmPath: process.env.PUBLIC_URL + '/opus-media-recorder/OggOpusEncoder.wasm',
  WebMOpusEncoderWasmPath: process.env.REACT_APP_URL + '/opus-media-recorder/WebMOpusEncoder.wasm',
};

export function MessageForm({ handleOpenManageMacroModal, canSendMessage, isAnnotation }: MessageFormProps) {
  const { handleSubmit, reset, formState, control } = useFormContext();

  const currentContact = useRef<Contact | null>(null);

  const [isRecordingAudio, setIsRecordingAudio] = useState(false);

  const { selectedContact } = useContacts();

  const theme = useTheme();

  const { revalidateCount } = useCount();

  const { timer, handleStart, handleReset } = useTimer();

  const queryClient = useQueryClient();

  const {
    messagesQuery: { query },
    setIsRefetchingMessages,
    selectedQuotedMessage,
    setSelectedQuotedMessage,
    setPreviewMessages,
  } = useMessages();

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);

  useEffect(() => {
    currentContact.current = selectedContact;
  }, [selectedContact]);

  function handleStop() {
    if (!mediaRecorder) return;

    mediaRecorder.removeEventListener('stop', handleStop);
    mediaRecorder.stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
  }

  async function sendAudioMessage({ quoted }: SendAudioMessageProps) {
    handleReset();

    if (!mediaRecorder) return;

    setPreviewMessages(prevMessages => {
      return [
        ...prevMessages,
        {
          id: uuidv4(),
          body: '',
          contact_id: currentContact.current ? currentContact.current.id : '',
          type: 'ptt',
          quoted: !isEmpty(quoted) ? quoted : '',
          caption: '',
          is_forwarded: false,
          time: new Date().toString(),
          from_me: true,
          is_read: true,
          ack: 'SENT',
        } as ChatMessage
      ]
    });

    mediaRecorder.addEventListener('dataavailable', async function handleDataAvailable(e) {
      if (!currentContact.current) return;

      mediaRecorder.removeEventListener('dataavailable', handleDataAvailable);
      mediaRecorder.addEventListener('stop', handleStop);

      const formData = new FormData();
      const audioFile = new File([e.data], 'audio', { type: 'audio/ogg;codecs=opus' });

      formData.append('contactId', currentContact.current.id);
      formData.append('file', audioFile);
      formData.append('quotedMsgId', !isEmpty(quoted) ? quoted.whatsapp_msg_id  : '');

      try {
        await api.post('/messages/send-ptt', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        setIsRefetchingMessages(true);
        await query.refetch();
        setPreviewMessages([]);
        setIsRefetchingMessages(false);
      } catch (error) {
        handleRequestError(error, 'Erro ao enviar mensagem. Por favor, tente novamente!');
      }
    });
  }

  async function sendTextMessage({ message, quoted, annotation }: SendTextMessageProps) {
    setPreviewMessages(prevMessages => {
      return [
        ...prevMessages,
        {
          id: uuidv4(),
          body: message,
          contact_id: currentContact.current ? currentContact.current.id : '',
          type: 'chat',
          quoted: !isEmpty(quoted) ? quoted : '',
          caption: '',
          is_forwarded: false,
          time: new Date().toString(),
          from_me: true,
          is_read: true,
          is_annotation: annotation,
          user_id: 'this-is-a-fake-user-id',
          ack: 'SENT',
        } as ChatMessage
      ]
    });

    try {
      await api.post('/messages/send-text', {
        contactId: currentContact.current?.id,
        body: message,
        quotedMsgId: !isEmpty(quoted) ? quoted.whatsapp_msg_id : null,
        isAnnotation: annotation,
      });

      setIsRefetchingMessages(true);
      await query.refetch();
      setPreviewMessages([]);
      setIsRefetchingMessages(false);
    } catch (error) {
      handleRequestError(error, 'Erro ao enviar mensagem. Por favor, tente novamente!');
    }
  }

  async function handleSendMessage({ message, annotation }: MessageData) {
    if (!currentContact.current) return;

    const quoted = selectedQuotedMessage;

    reset({
      annotation,
    });
    setSelectedQuotedMessage({} as ChatMessage);

    if (!message && !isRecordingAudio) return;

    if (isRecordingAudio) {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
      setIsRecordingAudio(false);
      await sendAudioMessage({ quoted });
    } else {
      await sendTextMessage({ message, quoted, annotation });
    }

    if (!!currentContact.current.archived_at) {
      revalidateCount();
    }

    if (annotation) {
      queryClient.invalidateQueries('contacts');
    }
  }

  function handleCancelRecordAudioMessage() {
    handleReset();
    setIsRecordingAudio(false);
    if (!mediaRecorder) return;
    mediaRecorder.stop();
    mediaRecorder.addEventListener('stop', handleStop);
  }

  const ReactSwal = withReactContent(Swal);

  function handleRecordAudioMessage() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        handleStart();
        setIsRecordingAudio(true);

        const recorder = new OpusRecorder(stream, {
          mimeType: 'audio/ogg;codecs=opus'
        }, workerOptions);

        setMediaRecorder(recorder);
        recorder.start();
      })
      .catch(error => {
        console.error(error);
        ReactSwal.fire({
          html: (
            <p>Você precisa conceder{' '}
              <a href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=pt-br" target="_blank" rel="noreferrer">
                permissão ao seu microfone
              </a>{' '}
              para gravar mensagens de áudio.
            </p>
          )
        });
      });
  }

  return (
    <Bottom>
      <Form onSubmit={handleSubmit(handleSendMessage)}>
        <FormRow>
          <InputGroup noSpacing flexItem>
            <ContentEditable
              icons={
                !isAnnotation ? (
                  <label htmlFor="sendFileMessage">
                    <AttachmentIcon className="input-icon" color={theme.colors.muted} size="18" />
                  </label>
                ) : undefined
              }
              control={control}
              name="message"
              id="message"
              placeholder="Digite sua mensagem..."
              padding=".8rem 3rem .8rem 1.5rem"
              disabled={isRecordingAudio}
              onSubmit={handleSubmit(handleSendMessage)}
            />
          </InputGroup>
          <InputGroup noSpacing>
            <Buttons>
              {isRecordingAudio && (
                <>
                  <Button onClick={handleCancelRecordAudioMessage} type="button" outline danger>
                    <Trash color={theme.colors.danger} size="18" />
                  </Button>
                  <RecordingTime>{formatTime(timer)}</RecordingTime>
                  <Button type="submit" disabled={formState.isSubmitting}>
                    <Send color={theme.colors.buttonText} size="18" />
                  </Button>
                </>
              )}

              <SendMessageButton
                formState={formState}
                isRecordingAudio={isRecordingAudio}
                onRecordAudioMessage={handleRecordAudioMessage}
                isAnnotation={isAnnotation}
              />
            </Buttons>
          </InputGroup>
        </FormRow>

        <MessageFormOptionsRow>
          <Footer>
            <div>
              <InputGroup noSpacing>
                <Checkbox
                  control={control}
                  name="annotation"
                  label="Anotação"
                  id="annotation"
                  disabled={!canSendMessage}
                />
              </InputGroup>
            </div>

            <Button
              type="button"
              onClick={handleOpenManageMacroModal}
              small
            >
              Macros
            </Button>
          </Footer>

          <SyncMessages />
        </MessageFormOptionsRow>
      </Form>
    </Bottom>
  );
}
