import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'

import Routes from "./routes";

import { AuthProvider } from "./contexts/AuthContext";

import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyle } from "./styles/global";

import light from 'styles/themes/light';
import dark from 'styles/themes/dark';

import { useDarkMode } from 'hooks/useDarkMode';
import ReactTooltip from 'react-tooltip';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Modal.setAppElement('#root');

function App() {
  const { isDarkMode } = useDarkMode();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={isDarkMode ? dark : light}>
        <AuthProvider>
          <ReactTooltip place="top" effect="solid" />
          <GlobalStyle />
          <ToastContainer position={toast.POSITION.TOP_CENTER} />
          <Routes />
        </AuthProvider>
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
