import { NewConversationModal } from "components/NewConversationModal";
import { useState } from "react";
import { Plus } from "react-feather";
import { useTheme } from "styled-components";
import { Button } from "styles/Button";
import { InputGroup } from "styles/InputGroup";

export function NewConversationButton() {
  const theme = useTheme();

  const [isNewConverastionModalOpen, setIsNewConverastionModalOpen] = useState(false);

  function handleOpenNewConversationModal() {
    setIsNewConverastionModalOpen(true);
  }

  function handleCloseNewConversationModal() {
    setIsNewConverastionModalOpen(false);
  }

  return (
    <>
      <InputGroup>
        <Button
          onClick={handleOpenNewConversationModal}
          fullwidth
        >
          <Plus color={theme.colors.buttonText} size="16" /> Nova conversa
        </Button>
      </InputGroup>

      <NewConversationModal
        isOpen={isNewConverastionModalOpen}
        onRequestClose={handleCloseNewConversationModal}
      />
    </>
  );
}
