import styled from "styled-components";

export const Container = styled.aside`
  height: 100%;
  border-right: 1px solid ${props => props.theme.colors.border};
  overflow-y: scroll;
`;

export const Heeader = styled.header`
  position: sticky;
  top: 0;
  padding: 1.25rem 1.25rem 1rem 1.25rem;
  background: ${props => props.theme.colors.contentBackground};
  border-bottom: 1px solid ${props => props.theme.colors.borderLight};
`;

export const Nav = styled.nav`
  padding: 0 1.25rem;
`;

export const MenuSection = styled.div`
  &:not(:first-child) {
    padding-top: 1rem;
    border-top: 1px solid ${props => props.theme.colors.borderLight};
  }

  span {
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    color: ${props => props.theme.colors.muted};
    text-transform: uppercase;
  }
`;

export const MenuItems = styled.div`
  padding: .5rem 0;
  border-radius: .5rem;

  a {
    display: block;
    text-decoration: none;
    padding: .5rem 0;
    cursor: pointer;
    font-weight: 400;
    color: ${props => props.theme.colors.text};

    &:hover {
      color: ${props => props.theme.colors.hoveredText};
    }

    &.active {
      color: ${props => props.theme.colors.primary};
      font-weight: bold;
    }
  }
`;
