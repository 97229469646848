import { Info } from "react-feather";
import { useTheme } from "styled-components";
import { MessageContainer } from "styles/MessageContainer";
import { CallAttempt } from "./styles";

export function CallLogMessage() {
  const theme = useTheme();

  return (
    <MessageContainer>
      <CallAttempt>
        <Info color={theme.colors.accent} size="16" />
        Tentativa de chamada realizada.
      </CallAttempt>
    </MessageContainer>
  );
}
