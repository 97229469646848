import styled, { css, keyframes } from "styled-components";
import { ContentWrapperProps, MessageContainerProps, MessageContentProps, MessageOptionsProps, TimeProps } from "./types";

export const Container = styled.div<MessageContainerProps>`
  margin-bottom: 0.625rem;
  display: flex;

  ${props => props.fromMe && css`
    justify-content: flex-end;
  `}
`;

export const MessageOptions = styled.div<MessageOptionsProps>`
  margin-top: .2rem;
  height: 1rem;
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease-in-out;

  ${props => props.isHighlighted && css`
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, .3);
    color: ${props => props.theme.colors.highlightedText};
  `}
`;

const blinkUnreadMessage = keyframes`
  from {
    opacity: 0.8;
  }

  to {
    opacity: 1;
  }
`;

export const Content = styled.div<MessageContentProps>`
  background: ${props => props.fromMe ? props.theme.colors.messageFromMe : props.theme.colors.defaultMessage};
  box-shadow: ${props => props.theme.styles.smallShadow};
  color: ${props => props.theme.colors.messageText};
  border-radius: 0.625rem;
  display: flex;
  position: relative;
  max-width: 75%;

  &:hover ${MessageOptions} {
    opacity: 1;
    visibility: visible;
  }

  ${props => props.isUnread && css`
    border: 1px solid ${props => props.theme.colors.accent};
    animation-name: ${blinkUnreadMessage};
    animation-duration: .3s;
    animation-timing-function: ease-out;
    animation-delay: 1s;
    animation-direction: alternate;
    animation-iteration-count: 5;
    animation-fill-mode: none;
    animation-play-state: running;
  `}

  ${props => props.isSending && css`
    opacity: 0.7;
  `}
`;

export const Options = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0.375rem;
  z-index: 998;
`;

export const Info = styled.span<TimeProps>`
  font-size: 0.75rem;
  color: ${props => props.theme.colors.messageOverlays};
  position: absolute;
  display: flex;
  align-items: flex-end;
  gap: .1rem;
  right: 0.375rem;
  bottom: 0.1875rem;
  white-space: nowrap;

  ${props => props.isHighlighted && css`
    padding: 1px 5px;
    border-radius: 1rem;
    background: rgba(0, 0, 0, .3);
    color: ${props => props.theme.colors.highlightedText};
  `}
`;

export const Seen = styled.div`
  display: flex;

  svg:last-child:not(:first-child) {
    margin-left: -8px;
  }
`;

export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  flex-direction: column;

  ${props => props.isMedia && css`
    max-width: 25rem;
  `}
`;

export const QuotedMessageContainer = styled.div`
  display: flex;
`;
