import { useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "services/api";
import { TecimobCategory } from "types/TecimobCategory";
import { handleRequestError } from "utils/Request";

type TecimobCategoriesParams = {}

export async function fetchTecimobCategories(params?: TecimobCategoriesParams): Promise<TecimobCategory[]> {
  const { data } = await api.get('/tecimob/categories', { params });
  return data;
}

export function useTecimobCategories(params?: TecimobCategoriesParams) {
  const query = useQuery('tecimobCategories', () => fetchTecimobCategories(params));

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Ocorreu um erro ao listar as categorias. Por favor, tente novamente!');
  }, [query.isError, query.error]);

  return query;
}
