import { ArchiveContactModal } from "components/ArchiveContactModal";
import { useContacts } from "hooks/useContacts";
import { useState } from "react";
import { Button } from "styles/Button";

export function ArchiveButton() {
  const [isOpen, setIsOpen] = useState(false);

  const { selectedContact } = useContacts();

  function handleOpenArchiveModal() {
    setIsOpen(true);
  }

  function handleCloseArchiveModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Button
        medium={!window.matchMedia("(max-width: 1366px)").matches}
        small={window.matchMedia("(max-width: 1366px)").matches}
        onClick={handleOpenArchiveModal}
      >
        Arquivar
      </Button>

      <ArchiveContactModal
        contact={{
          id: selectedContact.id,
          name: selectedContact.name,
          categoryId: selectedContact.category?.id,
          responsibleId: selectedContact.user?.id,
        }}
        isOpen={isOpen}
        onRequestClose={handleCloseArchiveModal}
      />
    </>
  );
}
