import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { useContacts } from "hooks/useContacts";

import { Contact } from "types/Contact";

import { axiosError, axiosSuccess, handleRequestError, handleRequestSuccess } from "utils/Request";

import { api } from "services/api";
import { useCount } from "hooks/utils/useCount";
import { ArchiveContactData } from "components/ArchiveContactModal/types";

interface ArchiveContactProps {
  contactId: string;
  data: ArchiveContactData;
}

async function archiveContact(contactId: string, data: ArchiveContactData): Promise<Contact> {
  const { data: archivedContact } = await api.post(`/contacts/archive/${contactId}`, data).then(axiosSuccess).catch(axiosError);
  return archivedContact;
}

export function useArchiveContact() {
  const { selectContact } = useContacts();

  const { revalidateCount } = useCount();

  return useMutation(({ contactId, data }: ArchiveContactProps) => archiveContact(contactId, data), {
    onSuccess: () => {
      handleRequestSuccess(`Contato arquivado com sucesso!`);
      selectContact({} as Contact);
      revalidateCount();
    },
    onError: (error: AxiosError) => {
      handleRequestError(error, 'Um erro ocorreu ao arquivar o contato. Por favor, tente novamente!');
    }
  });
}
