import { useComponentVisible } from 'hooks/useComponentVisible';
import { MouseEvent, useRef } from 'react';
import { Container, DropdownMenu } from './styles';
import { DropdownProps } from './types';

export function Dropdown({
  children,
  trigger,
  closeOnClickInside = false,
  mode = 'auto',
  pin = 'right',
}: DropdownProps) {
  const componentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const {
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(componentRef, null, triggerRef);

  function handleCloseOnClickInside() {
    if (!closeOnClickInside) return;
    setIsComponentVisible(false);
  }

  function handleTriggerClick(e: MouseEvent<HTMLDivElement>) {
    setIsComponentVisible(!isComponentVisible);
  }

  return (
    <Container>
      <div
        ref={triggerRef}
        className="trigger"
        aria-haspopup="true"
        aria-expanded={isComponentVisible}
        onClick={handleTriggerClick}
      >
        {trigger}
      </div>

      {isComponentVisible &&
        <DropdownMenu
          ref={componentRef}
          onClick={handleCloseOnClickInside}
          pin={pin}
          mode={mode}
        >
          {children}
        </DropdownMenu>
      }
    </Container>
  );
}
