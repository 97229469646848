import { useAudio } from "hooks/useAudio";
import { ArrowRightCircle } from "react-feather";
import { useTheme } from "styled-components";
import { MessageTitle } from "styles/MessageTitle";
import { Audio, AudioWrapper, Container, PlaybackSpeed } from "./styles";
import { AudioMessageProps } from "./types";
import ReactTooltip from 'react-tooltip';
import { useEffect } from "react";
import { AVAILABLE_AUDIO_SPEEDS } from "constants/config";

export function AudioMessage({ message, isAudioFile }: AudioMessageProps) {
  const { audioRef, currentSpeed, changeSpeed } = useAudio(AVAILABLE_AUDIO_SPEEDS);
  const theme = useTheme();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    audioRef.current?.load();
  }, [audioRef, message.body]);

  return (
    <Container data-tip={!message.body ? 'Áudio carregando. Por favor, aguarde.' : ''}>
      {message.is_forwarded && (
        <MessageTitle spaced={false}>
          <ArrowRightCircle size="18" color={theme.colors.text} />
          Encaminhada
        </MessageTitle>
      )}

      <AudioWrapper>
        <PlaybackSpeed isAudioFile={isAudioFile} onClick={changeSpeed}>{currentSpeed}x</PlaybackSpeed>
        <Audio ref={audioRef} fromMe={message.from_me} controls>
          <source src={message.body} type="audio/ogg" />
          Seu navegador não suporta mensagens de áudio
        </Audio>
      </AudioWrapper>
    </Container>
  );
}
