import styled, { css } from "styled-components";
import { MessageTitleProps } from "types/MessageTitle";

export const MessageTitle = styled.span<MessageTitleProps>`
  color: ${props => props.theme.colors.text};
  display: flex;
  align-items: center;
  gap: .5rem;
  font-weight: bold;

  ${props => props.spaced && css`
    margin-bottom: .3rem;
  `}
`;
