import { SettingsModal } from "components/SettingsModal";
import { useAuth } from "hooks/useAuth";
import { FormEvent, useState } from "react";
import { useTheme } from "styled-components";
import { Container, Logout, SettingsIcon, UserProfile } from "./styles";

import avatarImg from '../../assets/avatar.jpg';
import { TextEllipsis } from "components/TextEllipsis";

export function LoggedUser() {
  const theme = useTheme();
  const { user, logout } = useAuth();

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  function handleOpenSettingsModal() {
    setIsSettingsModalOpen(true);
  }

  function handleCloseSettingsModal() {
    setIsSettingsModalOpen(false);
  }

  function handleLogout(e: FormEvent) {
    e.preventDefault();
    logout();
  }

  return (
    <>
      <Container>
        <UserProfile>
          <img
            src={user?.image_url ?? avatarImg}
            alt={user?.name ?? 'Você'}
            loading="lazy"
            onError={(e) => e.currentTarget.src = avatarImg}
          />
          <TextEllipsis width={100}>
            {user?.name ?? 'Você'}
          </TextEllipsis>
        </UserProfile>
        <Logout>
          <SettingsIcon
            onClick={handleOpenSettingsModal}
            color={theme.colors.text}
            size="16"
          />
          <a href="./" onClick={handleLogout}>Sair</a>
        </Logout>
      </Container>

      <SettingsModal
        isOpen={isSettingsModalOpen}
        onRequestClose={handleCloseSettingsModal}
      />
    </>
  );
}
